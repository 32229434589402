import { removeColon } from '../fn';

type InputProps = {
  CONTROLLER: { pallet: string };
  PUT_BOX: { sscc: string };
  PUT_PLACE: { place: string };
};

export const CONTROL_PALLET_API = {
  CONTROLLER: (params: InputProps['CONTROLLER']) => {
    const { pallet } = params;
    return {
      params: removeColon(params),
      url: `control/pallet/${pallet}` as const,
    };
  },

  PUT_START: () => {
    return {
      url: `start` as const,
    };
  },

  PUT_RESET: () => {
    return {
      url: `reset` as const,
    };
  },

  PUT_PLACE: (params: InputProps['PUT_PLACE']) => {
    const { place } = params;
    return {
      params: removeColon(params),
      url: `place/${place}` as const,
    };
  },

  PUT_BOX: (params: InputProps['PUT_BOX']) => {
    const { sscc } = params;
    return {
      params: removeColon(params),
      url: `box/${sscc}` as const,
    };
  },

  PUT_FINISH: () => {
    return {
      url: `finish` as const,
    };
  },
} as const;
