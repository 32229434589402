import { createReducer, on } from '@ngrx/store';
import { ProductDefectDto } from '@monorepo/dictionaries/dto';
import { productDefectsActions } from './product-defects.actions';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';

export type ProductDefectsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: ProductDefectDto[];
};

export const initialProductDefectsState: ProductDefectsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.productDefects,
  data: [],
};

export const productDefectsReducer = createReducer(
  initialProductDefectsState,

  // state
  on(productDefectsActions.state.clear, () => ({
    ...initialProductDefectsState,
  })),

  // getProductDefects
  on(productDefectsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(productDefectsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(productDefectsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
