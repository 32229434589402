import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { CUSTOM_VALIDATORS } from '../../validators/custom-validators';

export type DimensionsValue = {
  length: number;
  width: number;
  height: number;
};

type AvailableSizes = {
  id: number;
  name: string;
  sizes: DimensionsValue | null;
};

const AVAILABLE_SIZES: AvailableSizes[] = [
  {
    id: 0,
    name: 'Вручную',
    sizes: null,
  },
  {
    id: 1,
    name: '60 x 40 x 50',
    sizes: { length: 60, width: 40, height: 50 },
  },
  {
    id: 2,
    name: '60 x 40 x 30',
    sizes: { length: 60, width: 40, height: 30 },
  },
];

@Component({
  selector: 'forms-dimensions',
  templateUrl: './dimensions-form.component.html',
  styleUrls: ['./dimensions-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionsFormComponent implements OnInit, OnDestroy {
  FIELD_LABELS = {
    length: 'Длина',
    width: 'Ширина',
    height: 'Высота',
  };

  @Input()
  set loading(v: boolean | null) {
    this.loading$.next(!!v);
  }
  get loading() {
    return this.loading$.value;
  }
  private loading$ = new BehaviorSubject<boolean>(false);
  @Input() availableSizes: AvailableSizes[] = AVAILABLE_SIZES;
  @Output() formSubmit = new EventEmitter<DimensionsValue>();

  form!: FormGroup;
  selectControl = new FormControl(0);

  destroyed$ = new Subject<void>();
  isMobile$ = this.breakpointObserver
    .observe(Breakpoints.XSmall)
    .pipe(map((r) => r.matches));

  showSelect() {
    return this.availableSizes.length > 0;
  }

  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver
  ) {}

  /**
   * * Init form
   * * Subscribe on `selectControl`
   * * Subscribe on Input loading prop
   */
  ngOnInit() {
    const validators = [
      Validators.required,
      Validators.min(1),
      CUSTOM_VALIDATORS.number,
    ];

    this.form = this.fb.group({
      length: [null, validators],
      width: [null, validators],
      height: [null, validators],
    });

    this.selectControl.valueChanges
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((v) => this.onSelectControlChanges(v));

    this.loading$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((v) => this.disableFormsIfLoading(v));
  }

  /**
   * Emits to destroy$
   */
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Form submit
   * * Emits form value if valid
   */
  onSubmit() {
    if (this.form.invalid) return;
    this.formSubmit.emit(this.form.value as DimensionsValue);
  }

  /**
   * Select control valueChanges handler
   */
  private onSelectControlChanges(v: number | null) {
    const foundSize = this.availableSizes.find((s) => s.id === v);
    if (!foundSize) return;

    if (foundSize.sizes) {
      this.form.setValue(foundSize.sizes);
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  /**
   * Used to reflect on Inputs changes
   *
   * Form should be enabled conditionally on `selectControl`'s state.
   * When `selectControl`'s availability changed, `valueChanges` will emit
   */
  private disableFormsIfLoading(loading: boolean | null) {
    if (loading) {
      this.selectControl.disable();
      this.form.disable();
    } else {
      this.selectControl.enable();
    }
  }
}
