export const outdatedMarkingCode = (mk: string) => false;
// todo: temporary disable check, waiting 1C
// mk.startsWith('01029');

export const markingCodeScanFailed = (markingCode: string) => {
  const mk31 = markingCode.slice(0, 31);
  return (
    mk31.endsWith('9100') || mk31.endsWith('91EE') || mk31.endsWith('9180')
  );
};

// 013454567545654521aaaaaaaaaaaaa is valid
export const isMarkingOrKizCodeValid = (markingCode: string): boolean => {
  return isMarkingCodeValid(markingCode) || isKizValid(markingCode);
};
const isMarkingCodeValid = (markingCode: string): boolean => {
  // prettier-ignore
  // eslint-disable-next-line
  const regExp = /^01[0-9]{14}21[a-zA-Z0-9\.\^\$\*\+\?\{\}\[\]\\\|\(\)_!"№;%:'--,=<>\/&]{13}/g;
  return !!markingCode.match(regExp);
};
const isKizValid = (kiz: string): boolean => {
  const regExpForKIZ = /^RU.{18}$/g;
  return !!kiz.match(regExpForKIZ);
};
