import { UntypedFormGroup } from '@angular/forms';

/**
 * Receives form and trims `formControl`s of this form
 * @param {FormGroup} formGroup 
 */
export const trimForm = (formGroup: UntypedFormGroup) => {
  Object.keys(formGroup.controls).forEach((key) => {
    const formControl = formGroup.get(key);
    const value = formControl?.value;
    const newValue = typeof value === 'string' ? value.trim() : value;
    formControl?.setValue(newValue);
  });
};
