import {HttpErrorResponse} from "@angular/common/http";

const ACTION_SCOPE = '[PlacesDictionary]';

export class Load {
  static readonly type = `${ACTION_SCOPE} Load All`;
}

export class ApiError {
  static readonly type = `${ACTION_SCOPE} API Error`;
  constructor(public error?: HttpErrorResponse | string) {}
}
