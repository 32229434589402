import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OffSystemStepsEffects } from './store/off-system-steps.effects';
import { OFF_SYSTEM_STEPS } from './off-system-steps.constants';
import { offSystemStepsReducer } from './store/off-system-steps.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      OFF_SYSTEM_STEPS.FEATURE_NAME,
      offSystemStepsReducer
    ),
    EffectsModule.forFeature([OffSystemStepsEffects]),
  ],
})
export class OffSystemStepsModule {}
