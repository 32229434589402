/**
 * @param {number} updatedAt
 * @param {string} [ttl]
 * @param {string} [currentTime]
 * @returns {number} tts >= 0
 */
export function timeUntilStale(
  updatedAt: number,
  ttl = 0,
  currentTime = Date.now()
): number {
  return Math.max(updatedAt + ttl - currentTime, 0);
}

export function isFresh(...params: Parameters<typeof timeUntilStale>): boolean {
  return timeUntilStale(...params) > 0;
}

export function isStale(...params: Parameters<typeof timeUntilStale>): boolean {
  return timeUntilStale(...params) <= 0;
}
