import {createAction, props} from "@ngrx/store";
import {LIST_GROUPS} from "../list-groups-all.constant";
import {ListGroupsState} from "./list-groups.reducer";

const typeName = (name: string) => `[${LIST_GROUPS.FEATURE_NAME}] ${name}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getListGroupsTrigger = createAction(typeName('getListGroups: Trigger'));
const getListGroupsSuccess = createAction(
  typeName('getListGroups: Success'),
  props<{ data: ListGroupsState['data'] }>()
);
const getListGroupsFailure = createAction(
  typeName('getListGroups: Failure'),
  failureProps
);
const getListGroupsIsFresh = createAction(typeName('getListGroups: Is Fresh'));
const getListGroupsCheckFreshness = createAction(
  typeName('getListGroups: Check Freshness')
);

export const listGroupsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getListGroupsTrigger,
    success: getListGroupsSuccess,
    failure: getListGroupsFailure,
    isFresh: getListGroupsIsFresh,
    checkFreshness: getListGroupsCheckFreshness,
  },
};
