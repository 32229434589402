export enum ControlBoxStep {
  _1_scan_box = '_1_scan_box',
  _2_scan_place = '_2_scan_place',
  /**
   * Optional step
   */
  _2_1_scan_work_place = '_2_1_scan_work_place',
  _3_scan_item = '_3_scan_item',
  /**
   * Optional step
   */
  _3_1_scan_keep_place = '_3_1_scan_keep_place',
  _4_scan_item_quantity = '_4_scan_item_quantity',
  _5_show_result = '_5_show_result',
}
