import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import {
  tcSelectors
} from "./transport-companies.selector";
import {
  transportCompaniesActions
} from "./transport-companies.actions";

// also facades can be used instead of effects
// https://gist.github.com/ThomasBurleson/38d067abad03b56f1c9caf28ff0f4ebd
@Injectable({
  providedIn: 'root',
})
export class TransportCompaniesFacade {
  loading$ = this.store.select(tcSelectors.loading);
  updatedAt$ = this.store.select(tcSelectors.updatedAt);
  data$ = this.store.select(tcSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(transportCompaniesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(transportCompaniesActions.getData.trigger());
  }
}
