import { createAction, props } from '@ngrx/store';
import { PRODUCT_ZONES } from '../product-zones.constants';
import { ProductZonesState } from './product-zones.reducer';

const typeName = (name: string) => `[${PRODUCT_ZONES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getProductZonesTrigger = createAction(
  typeName('getProductZones: Trigger')
);
const getProductZonesSuccess = createAction(
  typeName('getProductZones: Success'),
  props<{ data: ProductZonesState['data'] }>()
);
const getProductZonesFailure = createAction(
  typeName('getProductZones: Failure'),
  failureProps
);
const getProductZonesIsFresh = createAction(
  typeName('getProductZones: Is Fresh')
);
const getProductZonesCheckFreshness = createAction(
  typeName('getProductZones: Check Freshness')
);

export const productZonesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getProductZonesTrigger,
    success: getProductZonesSuccess,
    failure: getProductZonesFailure,
    isFresh: getProductZonesIsFresh,
    checkFreshness: getProductZonesCheckFreshness,
  },
};
