import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UsersEffects } from './store/users.effects';
import { USERS } from './users.constants';
import { usersReducer } from './store/users.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USERS.FEATURE_NAME, usersReducer),
    EffectsModule.forFeature([UsersEffects]),
    RouterModule.forChild([]),
  ],
})
export class UsersAllModule {}
