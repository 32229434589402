import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { placeTypesActions } from './place-types.actions';
import { placeTypesSelectors } from './place-types.selectors';
import { PlaceTypesService } from '../place-types.service';
import { isFresh } from '@monorepo/ui/helpers';

@Injectable()
export class PlaceTypesEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(placeTypesActions.getData.trigger),
      exhaustMap(() => {
        return this.placeTypesService.getData().pipe(
          map((data) => {
            return placeTypesActions.getData.success({ data });
          }),
          catchError((error) => {
            return of(placeTypesActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(placeTypesActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(placeTypesSelectors.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? placeTypesActions.getData.isFresh()
          : placeTypesActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(placeTypesActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private placeTypesService: PlaceTypesService
  ) {}
}
