import {createReducer, on} from "@ngrx/store";
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import {MistakeDto} from "@monorepo/administration/dto";
import {mistakesActions} from "./mistakes.actions";

export type MistakesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: MistakeDto[];
};
export const initialMistakesState: MistakesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.mistakes,
  data: [],
};

export const mistakesReducer = createReducer(
  initialMistakesState,

  // state
  on(mistakesActions.state.clear, () => ({ ...initialMistakesState })),

  // getUsers
  on(mistakesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(mistakesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(mistakesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
