import {inject, Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {PrintersDictionaryState} from "./printers.state";
import {map} from "rxjs/operators";

@Injectable()
export class PrintersDictionaryFacade {
  private store = inject(Store);
  public items$ = this.store.select(PrintersDictionaryState.getState).pipe(
    map((state) => state.items)
  )
}
