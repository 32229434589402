import { Injectable } from '@angular/core';
import { restQuery } from '@monorepo/ui/helpers';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { take, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { AccountHttpService } from './account-http.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private authService: AuthService,
    private accountHttpService: AccountHttpService,
    private snackService: SnackbarService
  ) {}

  changePlace = restQuery((place: string) => {
    return this.accountHttpService.getPlace(place).pipe(
      take(1),
      tap((place) => this.authService.setPlace(place)),
      tap(() => this.snackService.success({ title: 'Успешно' })),
      tap({ error: (error) => this.snackService.catchApiError({ error }) })
    );
  });
}
