import {createAction, props} from "@ngrx/store";
import {MISTAKES} from "../mistakes-all.constants";
import {MistakesState} from "./mistakes.reducer";

const typeName = (name: string) => `[${MISTAKES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getMistakesTrigger = createAction(typeName('getMistakes: Trigger'));
const getMistakesSuccess = createAction(
  typeName('getMistakes: Success'),
  props<{ data: MistakesState['data'] }>()
);
const getMistakesFailure = createAction(
  typeName('getMistakes: Failure'),
  failureProps
);
const getMistakesIsFresh = createAction(typeName('getMistakes: Is Fresh'));
const getMistakesCheckFreshness = createAction(
  typeName('getMistakes: Check Freshness')
);

export const mistakesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getMistakesTrigger,
    success: getMistakesSuccess,
    failure: getMistakesFailure,
    isFresh: getMistakesIsFresh,
    checkFreshness: getMistakesCheckFreshness,
  },
};
