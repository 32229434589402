import { OrderTypeDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { orderStatusesActions } from './order-statuses.actions';

export type OrderStatusesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: OrderTypeDto[];
};

export const initialOrderStatusesState: OrderStatusesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: 12 * 60 * 60 * 1000,
  data: [],
};

export const orderStatusesReducer = createReducer(
  initialOrderStatusesState,

  // state
  on(orderStatusesActions.state.clear, () => ({ ...initialOrderStatusesState })),

  // getData
  on(orderStatusesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(orderStatusesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(orderStatusesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
