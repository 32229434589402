import { inject, Injectable } from '@angular/core';
import { AuthResponseDto } from '@monorepo/auth/dto';
import { LoginSuccessPayload } from './store/auth.actions';
import { JWT_DECODE } from './auth-tokens';

@Injectable({
  providedIn: 'root',
  deps: [JWT_DECODE],
})
export class AuthDataAdapterService {
  private jwtDecode = inject(JWT_DECODE);

  httpLoginToState(data: AuthResponseDto): LoginSuccessPayload {
    const decoded = this.jwtDecode(data.token);
    return {
      user: {
        id: data.id,
        login: data.login,
        fullName: data.fullname,
        isActive: data.isActive,
        workPlace: decoded.work_place,
        department: decoded.department,
      },
      token: data.token,
      tokenExpires: new Date(decoded.exp * 1000),
    };
  }
}
