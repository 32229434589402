import { FormGroup } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';

/**
 * Adds `editStep` and `nextStep` functions,
 * used to control `mapStepper`
 */
export class StepperBase {
  editStep(data: { step: MatStep; form: FormGroup }) {
    const { step, form } = data;

    form.reset();
    step.editable = true;
    step.select();
  }

  nextStep(data: { matStepper?: MatStepper; stepNum?: number }) {
    const { matStepper, stepNum } = data;

    setTimeout(() => {
      if (!matStepper) {
        return console.warn('No MatStepper');
      }

      if (stepNum) {
        const providedStep = matStepper?.steps.get(stepNum);
        if (providedStep) {
          return providedStep?.select();
        }
      }

      const nextStep = matStepper.steps.find(
        (step) => step.editable && step !== matStepper?.selected
      );
      const lastStep = matStepper.steps.last;

      lastStep?.reset();

      if (nextStep) {
        matStepper.selected = nextStep;
        nextStep.select();
      } else {
        lastStep?.select();
      }
    }, 1);
  }
}
