import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import { inventoryCountingTypesActions } from './inventory-counting-types.actions';

export type InventoryCountingTypesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: any[];
};

export const initialInventoryCountingTypesState: InventoryCountingTypesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.inventoryCountingTypes,
  data: [],
};

export const inventoryCountingTypesReducer = createReducer(
  initialInventoryCountingTypesState,

  // state
  on(inventoryCountingTypesActions.state.clear, () => ({
    ...initialInventoryCountingTypesState,
  })),

  // getData
  on(inventoryCountingTypesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(inventoryCountingTypesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(inventoryCountingTypesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
