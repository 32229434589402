import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { orderStatusesActions } from './order-statuses.actions';
import { orderStatusesSelectors } from './order-statuses.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusesFacade {
  loading$ = this.store.select(orderStatusesSelectors.loading);
  updatedAt$ = this.store.select(orderStatusesSelectors.updatedAt);
  data$ = this.store.select(orderStatusesSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(orderStatusesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(orderStatusesActions.getData.trigger());
  }
}
