import { createActionsBulk } from '@monorepo/ui/helpers';
import { createAction, props } from '@ngrx/store';
import { AUTH } from '../auth.constants';
import { AuthState } from './auth.reducers';

export type LoginSuccessPayload = Omit<AuthState, 'loading'>;
export type LoginTriggerPayload = {
  login: string;
  password: string;
  workplace?: string | null;
};

const typeName = (name: string) => `[${AUTH.FEATURE_NAME}] ${name}`;

export const authActions = {
  state: createActionsBulk(typeName('state'), {
    clear: undefined,
  }),
  setPlace: createAction(
    typeName('setPlace'),
    props<{ place: { id: number; name: string } }>()
  ),
  login: createActionsBulk(typeName('login'), {
    trigger: props<LoginTriggerPayload>(),
    success: props<LoginSuccessPayload>(),
    failure: props<{ error?: any }>(),
  }),
  logout: createAction(typeName('logout')),
};
