import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TRADE_ITEM_GROUPS } from '../trade-item-groups.constants';
import { TradeItemGroupsState } from './trade-item-groups.reducer';

const state = createFeatureSelector<TradeItemGroupsState>(
  TRADE_ITEM_GROUPS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const tradeItemGroups = createSelector(
  state,
  ({ data: tradeItemGroups }) => tradeItemGroups
);

export const tradeItemGroupsSelectors = {
  state,
  loading,
  updatedAt,
  data: tradeItemGroups,
};
