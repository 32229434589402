import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  PwaUpdateDialogOpenFNType,
  PwaUpdateDialogComponent,
} from './pwa-update-dialog.component';

export type PwaUpdateDialogOpenArgs = {
  data: PwaUpdateDialogOpenFNType[1];
  config?: Omit<MatDialogConfig, 'data'>;
};

@Injectable({ providedIn: 'root' })
export class PwaUpdateDialogService {
  constructor(private dialog: MatDialog) {}

  ref: MatDialogRef<
    PwaUpdateDialogOpenFNType[0],
    PwaUpdateDialogOpenFNType[2]
  > | null = null;

  open({ data, config }: PwaUpdateDialogOpenArgs) {
    this.close();

    return (this.ref = this.dialog.open<
      PwaUpdateDialogOpenFNType[0],
      PwaUpdateDialogOpenFNType[1],
      PwaUpdateDialogOpenFNType[2]
    >(PwaUpdateDialogComponent, {
      disableClose: true,
      ...config,
      data,
    }));
  }

  close() {
    if (this.ref) {
      this.ref.close();
      this.ref = null;
    }
  }
}
