<div class="flex-content">
  <mat-icon *ngIf="getIcon()" class="info-icon" [ngClass]="getIconClass()">{{
    getIcon()
  }}</mat-icon>
  <div class="text-container">
    <p *ngIf="data.title" class="mat-headline-6" [title]="data.title">
      {{ data.title }}
    </p>
    <span class="message sz-body-large" [title]="data.message">{{ data.message }}</span>
  </div>
  <button (click)="close()" class="close-btn" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
