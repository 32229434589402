import { createAction, props } from '@ngrx/store';
import { DEPARTMENTS } from '../departments.constants';
import { DepartmentsState } from './departments.reducer';

const typeName = (name: string) => `[${DEPARTMENTS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getDepartmentsTrigger = createAction(typeName('getDepartments: Trigger'));
const getDepartmentsSuccess = createAction(
  typeName('getDepartments: Success'),
  props<{ data: DepartmentsState['data'] }>()
);
const getDepartmentsFailure = createAction(
  typeName('getDepartments: Failure'),
  failureProps
);
const getDepartmentsIsFresh = createAction(
  typeName('getDepartments: Is Fresh')
);
const getDepartmentsCheckFreshness = createAction(
  typeName('getDepartments: Check Freshness')
);

export const departmentsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getDepartmentsTrigger,
    success: getDepartmentsSuccess,
    failure: getDepartmentsFailure,
    isFresh: getDepartmentsIsFresh,
    checkFreshness: getDepartmentsCheckFreshness,
  },
};
