import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PRODUCT_DEFECTS } from '../product-defects.constants';
import { ProductDefectsState } from './product-defects.reducer';

const state = createFeatureSelector<ProductDefectsState>(
  PRODUCT_DEFECTS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const productDefects = createSelector(
  state,
  ({ data: productDefects }) => productDefects
);

export const productDefectsSelectors = {
  state,
  loading,
  updatedAt,
  data: productDefects,
};
