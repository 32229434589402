import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConditionsEffects } from './store/conditions.effects';
import { CONDITIONS } from './conditions.constants';
import { conditionsReducer } from './store/conditions.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(CONDITIONS.FEATURE_NAME, conditionsReducer),
    EffectsModule.forFeature([ConditionsEffects]),
    RouterModule.forChild([]),
  ],
})
export class ConditionsModule {}
