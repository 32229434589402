import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TcAllModule} from "./tc-all";
import {TcForIntegrationModule} from "./tc-for-integration";

@NgModule({
  imports: [
    CommonModule,
    TcAllModule,
    TcForIntegrationModule,
  ],
  exports: [
    TcAllModule,
    TcForIntegrationModule,
  ]
})
export class TransportCompaniesModule {}
