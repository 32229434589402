import { Injectable } from '@angular/core';
import { InventoryHttpService } from '../inventory-http.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryListStatusesService {
  constructor(private service: InventoryHttpService) {}

  getData() {
    return this.service.getListStatuses();
  }
}
