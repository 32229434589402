/* eslint-disable max-lines */
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { PwaUpdateActionsComponent } from './pwa-update-actions.component';

type DialogInputData = {
  updateNow: (ref: DialogRef) => void;
  updateIn: (ref: DialogRef, milliseconds: number) => void;
};
type DialogResult = void;
type DialogRef = MatDialogRef<PwaUpdateActionsComponent, DialogResult>;
export type PwaUpdateDialogOpenFNType = [
  PwaUpdateDialogComponent,
  DialogInputData,
  DialogResult
];

@Component({
  standalone: true,
  styles: [
    `
      :host {
        monorepo-pwa-update-actions {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .confirm-dialog__content {
          margin-block: 2ch;
        }
      }
    `,
  ],
  template: `
    <h2 mat-dialog-title class="confirm-dialog__title">{{ title }}</h2>

    <mat-dialog-content class="confirm-dialog__content">
      {{ description }}
    </mat-dialog-content>

    <mat-dialog-actions class="confirm-dialog__actions">
      <monorepo-pwa-update-actions
        (updateIn)="updateIn($event)"
        (updateNow)="updateNow()"
      >
      </monorepo-pwa-update-actions>
    </mat-dialog-actions>
  `,
  imports: [CommonModule, MatDialogModule, PwaUpdateActionsComponent],
})
export class PwaUpdateDialogComponent {
  title = 'Доступна новая версия';
  description =
    'Рекомендуется закрыть все вкладки приложения и нажать "Обновить".';

  constructor(
    public dialogRef: MatDialogRef<PwaUpdateActionsComponent, DialogResult>,
    @Inject(MAT_DIALOG_DATA) private data: DialogInputData
  ) {}

  updateNow() {
    this.data.updateNow(this.dialogRef);
  }
  updateIn(milliseconds: number) {
    this.data.updateIn(this.dialogRef, milliseconds);
  }
}
