import {Inject, Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {API_URL} from "@monorepo/ui/core";
import {makeUrl} from "@monorepo/ui/helpers";
import {DICTIONARIES_MOVEMENT_DOCUMENT_STATUS_API} from "@monorepo/dictionaries/shared";
import {MovementDocumentSuccessResponseDto} from "@monorepo/movement-document/dto";
import {MovementDocumentStatusDto} from "@monorepo/dictionaries/dto";

const { CONTROLLER, ALL } = DICTIONARIES_MOVEMENT_DOCUMENT_STATUS_API;
@Injectable({
  providedIn: 'root',
})
export class MovementDocumentStatusesHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getAll() {
    const { url } = ALL();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<MovementDocumentStatusDto[]>(URL);
  }
}
