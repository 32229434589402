import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { acceptanceStatusesActions } from './acceptance-statuses.actions';
import { acceptanceStatusesSelectors } from './acceptance-statuses.selectors';

// also facades can be used instead of effects
// https://gist.github.com/ThomasBurleson/38d067abad03b56f1c9caf28ff0f4ebd
@Injectable({
  providedIn: 'root',
})
export class AcceptanceStatusesFacade {
  loading$ = this.store.select(acceptanceStatusesSelectors.loading);
  updatedAt$ = this.store.select(acceptanceStatusesSelectors.updatedAt);
  data$ = this.store.select(acceptanceStatusesSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(acceptanceStatusesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(acceptanceStatusesActions.getData.trigger());
  }
}
