import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {MISTAKES} from "./mistakes-all.constants";
import {mistakesReducer} from "./store/mistakes.reducer";
import {MistakesEffects} from "./store/mistakes.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MISTAKES.FEATURE_NAME, mistakesReducer),
    EffectsModule.forFeature([MistakesEffects])
  ]
})
export class MistakesAllModule {}
