import {Injectable} from "@angular/core";
import {
  TransportCompaniesHttpService
} from "../transport-companies-http.service";

@Injectable({
  providedIn: 'root',
})
export class TcForIntegrationService {
  constructor(private tcHttpService: TransportCompaniesHttpService) {
  }

  getData() {
    return this.tcHttpService.getForIntegration();
  }
}
