import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TradeItemGroupsModule } from './trade-item-groups';
import { DefectCommentsModule } from './defect-comments';
import { ProductDefectsModule } from './product-defects';
import { ProductZonesModule } from './product-zones';
import { DefectEliminationsModule } from './defect-eliminations';
import { UsersModule } from './users/users.module';
import { ConditionsModule } from './conditions';
import { AcceptanceStatusesModule } from './acceptance-statuses';
import { AcceptanceTypesModule } from './acceptance-types';
import { BusinessPartnersModule } from './business-partners';
import { InventoryModule } from './inventory/inventory.module';
import { ZonesModule } from './zones';
import { OrdersModule } from './orders';
import { SsccTypesModule } from './sscc-types';
import { PlaceTypesModule } from './place-types';
import { DepartmentsModule } from './departments';
import { OffSystemStepsModule } from './off-system-steps';
import { SupervisorsModule } from "./supervisors/supervisors.module";
import { MistakesModule } from "./mistakes/mistakes.module";
import {
  MovementDocumentStatusesModule
} from "./movement-document-statuses/movement-document-statuses.module";
import {
  MovementDocumentObjectModule
} from "./movement-document-object/movement-document-object.module";
import { ListGroupsModule } from "./list-groups";
import {
  TransportCompaniesModule
} from "./transport-companies/transport-companies.module";
import {RegistryTypesModule} from "./registry-types/registry-types.module";
import {RegistryStatusesModule} from "./registry-statuses/registry-statuses.module";
import {MessageStatusesModule} from "./message-statuses/message-statuses.module";

const MODULES = [
  TradeItemGroupsModule,
  DefectCommentsModule,
  ProductDefectsModule,
  DefectEliminationsModule,
  ProductZonesModule,
  UsersModule,
  SupervisorsModule,
  MistakesModule,
  ListGroupsModule,
  ConditionsModule,
  AcceptanceStatusesModule,
  AcceptanceTypesModule,
  BusinessPartnersModule,
  InventoryModule,
  ZonesModule,
  OrdersModule,
  SsccTypesModule,
  PlaceTypesModule,
  DepartmentsModule,
  OffSystemStepsModule,
  MovementDocumentStatusesModule,
  MovementDocumentObjectModule,
  TransportCompaniesModule,
  RegistryTypesModule,
  RegistryStatusesModule,
  MessageStatusesModule,
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: [...MODULES],
})
export class DictionariesServicesModule {}
