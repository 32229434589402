<form [formGroup]="form" (ngSubmit)="save()">
  <mat-form-field>
    <mat-label>{{ FIELD_LABELS.name }}</mat-label>
    <input matInput autocomplete="off" formControlName="name" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ FIELD_LABELS.fullname }}</mat-label>
    <input matInput autocomplete="off" formControlName="fullname" />
  </mat-form-field>

  <ng-template #selectOptionLoading>
    <span
      style="display: flex; align-items: center; justify-content: space-between"
    >
      Загрузка
      <mat-spinner diameter="20" style="display: inline-block"> </mat-spinner>
    </span>
  </ng-template>

  <mat-form-field>
    <mat-select
      [placeholder]="FIELD_LABELS.departmentId"
      formControlName="departmentId"
    >
      <mat-option *ngIf="departmentsDictionary.loading$ | async as isLoading">
        <ng-container *ngTemplateOutlet="selectOptionLoading"></ng-container>
      </mat-option>

      <ng-container *ngIf="departmentsDictionary.data$ | async as options">
        <mat-option *ngFor="let item of options" [value]="item.id">
          {{ item.name }}
        </mat-option>

        <mat-option
          *ngIf="
            (departmentsDictionary.loading$ | async) === false &&
            (departmentsDictionary.updatedAt$ | async) &&
            !options?.length
          "
        >
          Пусто
        </mat-option>

        <mat-option
          *ngIf="
            (departmentsDictionary.loading$ | async) === false &&
            (departmentsDictionary.updatedAt$ | async) === 0 &&
            !options?.length
          "
          ripple
        >
          Не удалось получить список.
          <button
            type="button"
            mat-stroked-button
            (click)="departmentsDictionary.refresh()"
          >
            Попробовать ещё раз
          </button>
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <mat-checkbox formControlName="isActive">
    {{ FIELD_LABELS.isActive }}
  </mat-checkbox>

  <forms-all-errors [form]="form" [fields]="FIELD_LABELS"></forms-all-errors>

  <div class="actions">
    <button
      type="button"
      mat-raised-button
      (click)="close()"
      [disabled]="loading"
    >
      Назад
    </button>

    <button
      type="submit"
      color="primary"
      mat-raised-button
      [disabled]="form.invalid || loading"
    >
      <mat-spinner
        *ngIf="loading"
        diameter="20"
        style="display: inline-block"
      ></mat-spinner>
      <span>{{ submitButton }}</span>
    </button>
  </div>
</form>
