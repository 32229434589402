import {MovementDocumentObjectHttpService} from "../movement-document-object-http.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ObjectAllService {
  constructor(private objectsHttpService: MovementDocumentObjectHttpService) {
  }

  getData() {
    return this.objectsHttpService.getAll();
  }
}
