import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UsersMastersEffects } from './store/users-masters.effects';
import { USERS_MASTERS } from './users-masters.constants';
import { usersMastersReducer } from './store/users-masters.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(USERS_MASTERS.FEATURE_NAME, usersMastersReducer),
    EffectsModule.forFeature([UsersMastersEffects]),
    RouterModule.forChild([]),
  ],
})
export class UsersMastersModule {}
