import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DepartmentsEffects } from './store/departments.effects';
import { DEPARTMENTS } from './departments.constants';
import { departmentsReducer } from './store/departments.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DEPARTMENTS.FEATURE_NAME, departmentsReducer),
    EffectsModule.forFeature([DepartmentsEffects]),
  ],
})
export class DepartmentsModule {}
