import {Injectable} from "@angular/core";
import {tap} from "rxjs";
import {Store} from "@ngrx/store";
import {listGroupsSelectors} from "./list-groups.selector";
import {listGroupsActions} from "./list-groups.actions";

@Injectable({
  providedIn: 'root',
})
export class ListGroupsFacade {
  loading$ = this.store.select(listGroupsSelectors.loading);
  updatedAt$ = this.store.select(listGroupsSelectors.updatedAt);
  data$ = this.store.select(listGroupsSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(listGroupsActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(listGroupsActions.getData.trigger());
  }
}
