import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INVENTORY_LIST_STATUSES } from '../inventory-list-statuses.constants';
import { InventoryListStatusesState } from './inventory-list-statuses.reducer';

const state = createFeatureSelector<InventoryListStatusesState>(
  INVENTORY_LIST_STATUSES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const inventoryListStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
