import { KeyValue } from '@angular/common';

const ERRORS = {
  required: 'Обязательное поле',
  email: 'Поле должно содержать email',
  pattern: 'Не соответствует паттерну',
  max: (val: any) => `Максимальное число ${val?.max}`,
  min: (val: any) => `Минимальное число ${val?.min}`,
  maxlength: (val: any) => `Максимальная длина ${val?.requiredLength}`,
  minlength: (val: any) => `Минимальная длина ${val?.requiredLength}`,
};

export const stringifyError = ({ key, value }: KeyValue<string, any>) => {
  if (typeof value === 'string') return value;

  const error = ERRORS?.[<keyof typeof ERRORS>key] || '';

  if (typeof error === 'string') return error;

  return error(value);
};

stringifyError.ERRORS = ERRORS