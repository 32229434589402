import { Injectable } from '@angular/core';
import { UsersHttpService } from '../users-http.service';

@Injectable({
  providedIn: 'root',
})
export class UsersMastersService {
  constructor(private usersHttpService: UsersHttpService) {}

  getData() {
    return this.usersHttpService.getByRole({ role: 'master' });
  }
}
