import { NgModule } from '@angular/core';
import { ZonesAllModule } from './zones-all';
import { ZonesForLockModule } from './zones-for-lock';
import { ZonesInventoryModule } from './zones-inventory';

@NgModule({
  imports: [ZonesAllModule, ZonesInventoryModule, ZonesForLockModule],
  exports: [ZonesAllModule, ZonesInventoryModule, ZonesForLockModule],
})
export class ZonesModule {}
