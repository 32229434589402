import { RemoveColonReturnType } from '@monorepo/shared';

/**
 // todo: move to shared-v2
 * @param obj 
 * @returns 
 */
export const removeColon = <A extends Record<string, string>>(obj: A) => {
  const entries = Object.entries(obj);
  const result: RemoveColonReturnType<A> | Record<string, unknown> = {};

  for (const [key, value] of entries) {
    if (typeof value !== 'string') throw `${key} отсутствует`;
    result[key] = value.replace(':', '');
  }
  return result as RemoveColonReturnType<A>;
};
