import { BusinessPartnerDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { businessPartnersActions } from './business-partners.actions';

export type BusinessPartnersState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: BusinessPartnerDto[];
};

export const initialBusinessPartnersState: BusinessPartnersState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.businessPartners,
  data: [],
};

export const businessPartnersReducer = createReducer(
  initialBusinessPartnersState,

  // state
  on(businessPartnersActions.state.clear, () => ({
    ...initialBusinessPartnersState,
  })),

  // getBusinessPartners
  on(
    businessPartnersActions.getData.success,
    (state, { data: data }) => {
      return { ...state, data, loading: false, updatedAt: Date.now() };
    }
  ),

  // loadings
  on(businessPartnersActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(businessPartnersActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
