import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatStep, MatStepLabel } from '@angular/material/stepper';

export type CustomMatStepEditSignal = { step: MatStep; form: FormGroup };

@Component({
  selector: 'forms-mat-step',
  template: `
    <form [formGroup]="form"></form>

    <ng-template matStepContent>
      <ng-content></ng-content>
    </ng-template>

    <ng-template matStepLabel>
      <div style="display: flex; align-items: center;">
        {{ label }}
        <button
          color="primary"
          mat-icon-button
          *ngIf="form.valid && !disabled; else nbsp"
          (click)="onEdit(); $event.stopPropagation()"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <ng-template #nbsp>&nbsp;</ng-template>
        <b>{{ data }}</b>
      </div>
    </ng-template>
  `,
  styles: [``],
  providers: [
    { provide: MatStep, useExisting: CustomMatStepComponent },
    { provide: ErrorStateMatcher, useExisting: CustomMatStepComponent },
  ],
})
/**
 * Extends `MatStep` to easy provide step information
 *
 * ```ts
 *    <forms-mat-step
 *      state="marking" // to provide icons
 *      label="Код маркировки"
 *      [form]="markingFormGroup"
 *      [disabled]="disabledSteps.isMarking"
 *      [data]="markingFormGroup.value.markingCode"
 *      (edit)="editStep($event)"
 *    >
 *      <%= YOUR CONTENT %>
 *    <forms-mat-step/>
 * ```
 */
export class CustomMatStepComponent
  extends MatStep
  implements OnInit, OnChanges, AfterViewInit
{
  @Output()
  edit = new EventEmitter<CustomMatStepEditSignal>();

  @ViewChild(MatStepLabel)
  matStepLabel!: MatStepLabel;

  @Input()
  override label!: string;

  @Input()
  override state!: string;

  @Input()
  form!: FormGroup;

  @Input()
  disabled!: boolean;

  @Input()
  data!: string | number | null | undefined;

  ngOnInit() {
    this.stepControl = this.form;
  }

  override ngOnChanges(): void {
    this.editable = this.form.invalid && !this.disabled;
    this.color = this.disabled ? 'accent' : 'primary';
    this.completed = this.disabled || this.form.valid;
  }

  ngAfterViewInit(): void {
    this.stepLabel = this.matStepLabel;
  }

  onEdit() {
    this.edit.emit({ step: this, form: this.form });
  }
}
