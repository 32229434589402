import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'forms-scan-registry',
  styleUrls: ['../shared-styles.scss'],
  template: ` <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Отсканируйте реестр</mat-label>
      <input
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true"
        cdkFocusInitial
        type="number"
        matInput
        placeholder="123"
        formControlName="registryId"
      />
    </mat-form-field>

    <button
      type="submit"
      color="primary"
      mat-raised-button
      [disabled]="form.invalid || loading"
    >
      <mat-spinner
        *ngIf="loading"
        diameter="20"
        style="display: inline-block"
      ></mat-spinner>
      <span>Отправить</span>
    </button>
  </form>`,
})
export class ScanRegistryComponent {
  @Input()
  loading?: boolean | null;

  form = new UntypedFormGroup({
    registryId: new UntypedFormControl('', [Validators.required]),
  });

  @Output()
  formSubmit = new EventEmitter<number>();

  onSubmit() {
    const registryId = this.form.value.registryId;

    if (!registryId) {
      return;
    }

    this.formSubmit.emit(registryId);
  }
}
