import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import { LIST_GROUPS } from "./list-groups-all.constant";
import { listGroupsReducer } from "./store/list-groups.reducer";
import { ListGroupsEffects } from "./store/list-groups.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(LIST_GROUPS.FEATURE_NAME, listGroupsReducer),
    EffectsModule.forFeature([ListGroupsEffects])
  ]
})
export class ListGroupsAllModule {}
