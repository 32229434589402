import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PLACE_TYPES } from '../place-types.constants';
import { PlaceTypesState } from './place-types.reducer';

const state = createFeatureSelector<PlaceTypesState>(PLACE_TYPES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const placeTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
