import {createReducer, on} from "@ngrx/store";
import {
  registryStatusesActions
} from "./registry-statuses.actions";
import {DictionariesCacheTime} from "../../../../dictionaries.cache-time";
import {RegistryStatusDto} from "@monorepo/registry/dto";

export type RegistryStatusesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: RegistryStatusDto[];
};
export const initialRegistryStatusesState: RegistryStatusesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.registryStatuses,
  data: [],
};

export const registryStatusesReducer = createReducer(
  initialRegistryStatusesState,

  // state
  on(registryStatusesActions.state.clear, () => ({ ...initialRegistryStatusesState })),

  // getUsers
  on(registryStatusesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(registryStatusesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(registryStatusesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
