import { createAction, props } from '@ngrx/store';
import { OFF_SYSTEM_STEPS } from '../off-system-steps.constants';
import { OffSystemStepsState } from './off-system-steps.reducer';

const typeName = (name: string) => `[${OFF_SYSTEM_STEPS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getOffSystemStepsTrigger = createAction(
  typeName('getOffSystemSteps: Trigger')
);
const getOffSystemStepsSuccess = createAction(
  typeName('getOffSystemSteps: Success'),
  props<{ data: OffSystemStepsState['data'] }>()
);
const getOffSystemStepsFailure = createAction(
  typeName('getOffSystemSteps: Failure'),
  failureProps
);
const getOffSystemStepsIsFresh = createAction(
  typeName('getOffSystemSteps: Is Fresh')
);
const getOffSystemStepsCheckFreshness = createAction(
  typeName('getOffSystemSteps: Check Freshness')
);

export const offSystemStepsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getOffSystemStepsTrigger,
    success: getOffSystemStepsSuccess,
    failure: getOffSystemStepsFailure,
    isFresh: getOffSystemStepsIsFresh,
    checkFreshness: getOffSystemStepsCheckFreshness,
  },
};
