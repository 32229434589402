<div class="container p-16 col gap-8">
  <div class="header col gap-16">
    <div class="sz-title-extra-large">
      12 STOREEZ WMS
    </div>
    <div class="sz-title-large">
      Вход в систему
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <sz-input
      #login
      autofocus
      tag="Логин"
      id="wms.login"
      formControlName="login"
      autocomplete="off"
      [disabled]="(loading$ | async) === true"
    />
    <sz-input
      tag="Пароль"
      id="wms.password"
      formControlName="password"
      autocomplete="off"
      [disabled]="(loading$ | async) === true"
    />

    @if (form.controls.workplace.enabled) {
      <sz-input
        tag="Рабочее место"
        id="wms.workplace"
        formControlName="workplace"
        autocomplete="off"
        [disabled]="(loading$ | async) === true"
      />
    }

    <div class="p-16">
      <button
        szButton
        type="submit"
        class="w-100"
        [disabled]="(loading$ | async) || form.invalid"
        [szLoading]="loading$ | async"
      >
        Войти
      </button>
    </div>
  </form>
</div>

