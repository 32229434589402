import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { QRCodeToDataURLOptions, toDataURL } from 'qrcode';
import * as JsBarcode from 'jsbarcode';
import * as FileSaver from 'file-saver';
import { Printd } from 'printd';
import { exhaustMap, from, Observable } from 'rxjs';
import * as Bowser from 'bowser';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

type OpenFileProps = {
  arrayBuffer: ArrayBuffer;
  contentType: 'application/pdf' | string;
  print?: boolean;
  target?: '_blank';
  features?: 'toolbar=0,location=0,menubar=0';
};

interface NativePrint {
  htmlNode: Node;
  fileName?: string;
  timeoutTime?: number;
  useExistingCss?: boolean;
  readyPromise?: Promise<any>;
  extraStyles?: string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getBrowserParser() {
    return Bowser.getParser(window.navigator.userAgent);
  }

  saveAsExcelFile(props: { arrayBuffer: ArrayBuffer; fileName: string }) {
    const { arrayBuffer, fileName } = props;
    const data: Blob = new Blob([arrayBuffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + ' ' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  generateBarcodeImage(
    barcode: string | number,
    options: JsBarcode.Options = {}
  ) {
    const image = this.document.createElement('img');
    JsBarcode(image, barcode.toString(), { displayValue: false, ...options });
    return image;
  }

  async generateQrCodeDataUrl(
    text: string | number,
    options: QRCodeToDataURLOptions = {}
  ): Promise<string> {
    return toDataURL(text.toString(), { ...options });
  }

  async generateQrCodeImage(
    text: string | number,
    options: QRCodeToDataURLOptions = {}
  ): Promise<HTMLImageElement> {
    const image = this.document.createElement('img');
    image.src = await this.generateQrCodeDataUrl(text, options);
    return image;
  }

  openFileToPrint = ({
    arrayBuffer,
    contentType,
    print = true,
    target = '_blank',
    features = 'toolbar=0,location=0,menubar=0',
  }: OpenFileProps) => {
    // todo: make function printPdf
    // defaultView returns window
    const browserWindow = this.document.defaultView;

    const fileBlob = new Blob([arrayBuffer], { type: contentType });
    const fileURL = URL.createObjectURL(fileBlob);

    const myWindow = browserWindow?.open(fileURL, target, features);

    if (print) {
      myWindow?.print();
    }
  };

  /**
   * Uses `Printd` that uses `window.print()` inside <iframe/>.
   * There is nice package for Angular - ngx-print.
   * But it is not working in mobile correctly,
   * so might be rewrited: https://github.com/selemxmn/ngx-print/issues/120
   */
  nativePrint(data: NativePrint): Observable<void> {
    const {
      htmlNode: htmlNode,
      useExistingCss = true,
      extraStyles: _extraStyles,
      fileName = Date.now().toString(),
      timeoutTime = 0,
      readyPromise = new Promise((r) => setTimeout(r, timeoutTime)),
    } = data;

    let headElements: HTMLElement[] | undefined = undefined;
    if (useExistingCss) {
      headElements = [
        ...Array.from(this.document.getElementsByTagName('style')).map(
          (n) => n.cloneNode(true) as HTMLElement
        ),
        ...Array.from(this.document.getElementsByTagName('link')).map(
          (n) => n.cloneNode(true) as HTMLElement
        ),
      ];
    }

    const extraStyles =
      typeof _extraStyles === 'string'
        ? [_extraStyles]
        : Array.isArray(_extraStyles)
        ? _extraStyles
        : [];

    const d = new Printd({ headElements });
    const oldTitle = document.title;

    const printObservable = new Observable<void>((sub) => {
      let afterprintCalled = false;
      let iframe: HTMLIFrameElement | undefined;
      const onafterprint = () => {
        if (afterprintCalled) return;
        afterprintCalled = true;
        this.document.title = oldTitle;
        iframe?.remove();
        sub.next();
        sub.complete();
      };

      const onbeforeprint = () => {
        this.document.title = fileName;
      };

      const isDesktop = this.getBrowserParser().getPlatformType() === 'desktop';
      const isMobile = !isDesktop; // every platform uses chrome

      d.print(
        htmlNode.cloneNode(true) as HTMLElement,
        extraStyles,
        [],
        ({ iframe: _iframe, launchPrint }) => {
          iframe = _iframe;

          /**
           * # Focus and blur events sequence on window:
           *
           * Chrome desktop:
           *  > `printing` -> focus -> focusin // ❌ WTF?
           *
           * Firefox desktop:
           *  > `printing` -> focusout -> blur -> focus -> focusin // ❌ WTF?
           *
           * Chrome mobile:
           *  > focusout -> blur -> `printing` -> focus -> focusin // ✅ NICE
           */
          onbeforeprint();

          isMobile &&
            (window.onfocus = function () {
              onafterprint();
              window.onfocus = null;
            });

          launchPrint();
          /**
           * Printing blocs the main thread on Chrome and Firefox desktop,
           * so after this line the code will be executed after print.
           *
           * On Chrome mobile, the code will be executed before print. 🤬
           *
           * Best solution is:
           *   Desktop:
           *      use setTimeout(1s) before print and before `generatePackingListV2` or use this line
           *   Mobile:
           *      use `window.onfocus` event on mobile as it done above.
           */
          // console.log('after print); // desktop;
          // console.log('before print); // mobile;
          isDesktop && onafterprint();
        }
      );

      return () => onafterprint();
    });

    return from(readyPromise).pipe(exhaustMap(() => printObservable));
  }
}
