import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { isFresh } from '@monorepo/ui/helpers';
import {
  ObjectAllService
} from "../object-all.service";
import {
  movementDocObjectsSelector
} from "./movement-object.selector";
import {
  movementDocObjectsActions
} from "./movement-object.actions";

@Injectable()
export class MovementObjectEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(movementDocObjectsActions.getData.trigger),
      exhaustMap(() => {
        return this.objectsAllService.getData().pipe(
          map((data) => {
            return movementDocObjectsActions.getData.success({ data: data });
          }),
          catchError((error) => {
            return of(movementDocObjectsActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(movementDocObjectsActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(movementDocObjectsSelector.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? movementDocObjectsActions.getData.isFresh()
          : movementDocObjectsActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(movementDocObjectsActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private objectsAllService: ObjectAllService
  ) {}
}
