import { createAction, props } from '@ngrx/store';
import { ACCEPTANCE_TYPES } from '../acceptance-types.constants';
import { AcceptanceTypesState } from './acceptance-types.reducer';

const typeName = (name: string) => `[${ACCEPTANCE_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getAcceptanceTypesTrigger = createAction(
  typeName('getAcceptanceTypes: Trigger')
);
const getAcceptanceTypesSuccess = createAction(
  typeName('getAcceptanceTypes: Success'),
  props<{ data: AcceptanceTypesState['data'] }>()
);
const getAcceptanceTypesFailure = createAction(
  typeName('getAcceptanceTypes: Failure'),
  failureProps
);
const getAcceptanceTypesIsFresh = createAction(
  typeName('getAcceptanceTypes: Is Fresh')
);
const getAcceptanceTypesCheckFreshness = createAction(
  typeName('getAcceptanceTypes: Check Freshness')
);

export const acceptanceTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getAcceptanceTypesTrigger,
    success: getAcceptanceTypesSuccess,
    failure: getAcceptanceTypesFailure,
    isFresh: getAcceptanceTypesIsFresh,
    checkFreshness: getAcceptanceTypesCheckFreshness,
  },
};
