import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TcForIntegrationState
} from "./transport-companies-for-integration.reducer";
import {TRANSPORT_COMPANIES_FOR_INTEGRATION} from "../tc-for-integration.constants";

const state = createFeatureSelector<TcForIntegrationState>(TRANSPORT_COMPANIES_FOR_INTEGRATION.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const tcForIntegrationSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
