import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH } from '../auth.constants';
import { AuthState } from './auth.reducers';

const state = createFeatureSelector<AuthState>(AUTH.FEATURE_NAME);

const user = createSelector(state, ({ user }) => user);
const token = createSelector(state, ({ token }) => token);
const authorized = createSelector(token, (token) => !!token);
const loading = createSelector(state, ({ loading }) => loading);
const fullName = createSelector(user, (user) => user?.fullName);
const workPlace = createSelector(user, (user) => user?.workPlace);
const department = createSelector(user, (user) => user?.department);
const tokenExpires = createSelector(state, ({ tokenExpires }) => tokenExpires);

export const authSelectors = {
  user,
  token,
  loading,
  authorized,
  fullName,
  workPlace,
  department,
  tokenExpires,
};
