import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS } from '../users.constants';
import { UsersState } from './users.reducer';

const state = createFeatureSelector<UsersState>(USERS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const usersSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
