import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderTypesEffects } from './store/order-types.effects';
import { ORDER_TYPES } from './order-types.constants';
import { orderTypesReducer } from './store/order-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ORDER_TYPES.FEATURE_NAME, orderTypesReducer),
    EffectsModule.forFeature([OrderTypesEffects]),
  ],
})
export class OrderTypesModule {}
