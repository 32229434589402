import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryTypesModule } from './inventory-types';
import { InventoryStatusesModule } from './inventory-statuses';
import { InventoryCountingTypesModule } from './inventory-counting-types';
import { InventoryListStatusesModule } from './inventory-list-statuses/';

const inventoryModules = [
  InventoryTypesModule,
  InventoryStatusesModule,
  InventoryCountingTypesModule,
  InventoryListStatusesModule,
];

@NgModule({
  imports: [CommonModule, ...inventoryModules],
  exports: [...inventoryModules],
})
export class InventoryModule {}
