import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'monorepo-styles-provider',
  template: ``,
  styleUrls: ['./styles-provider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StylesProviderComponent implements OnInit {
  ngOnInit() {
    console.log('🟢 Styles provider')
  }
}
