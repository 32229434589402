import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { isFresh } from '@monorepo/ui/helpers';
import {
  registryStatusesActions
} from "./registry-statuses.actions";
import {
  RegistryStatusesAllService
} from "../registry-statuses-all.service";
import {
  registryStatusesSelectors
} from "./registry-statuses.selector";

@Injectable()
export class RegistryStatusesEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registryStatusesActions.getData.trigger),
      exhaustMap(() => {
        return this.registryStatusesAllService.getData().pipe(
          map((res) => {
            return registryStatusesActions.getData.success({ data: res.data});
          }),
          catchError((error) => {
            return of(registryStatusesActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registryStatusesActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(registryStatusesSelectors.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? registryStatusesActions.getData.isFresh()
          : registryStatusesActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(registryStatusesActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private registryStatusesAllService: RegistryStatusesAllService
  ) {}
}
