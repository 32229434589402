import { Directive, Input } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, of } from 'rxjs';

/**
 * Extend this class to add validators to your field component.
 *
 * Only `validators$` available.
 * Do not use `validators` - this getter reserved for `@Input`
 *
 * ```ts
 * @Component({
 *  selector: 'forms-scan-any',
 *  template: `<forms-field [validators]="validators$ | async"/>`,
 * })
 * export class ScanAnyComponent extends WithValidators {
 * constructor() {
 *   super(CUSTOM_VALIDATORS_PRESETS.any);
 * }
 * ngOnInit() {
 *  this.validators$.subscribe(console.log)
 *  -> [...CUSTOM_VALIDATORS_PRESETS.any, ...inputValidators]
 * }
 * ```
 *
 * Then pass validators as input from parent
 *
 * ```html
 * <forms-scan-any [validators]="inputValidators"></forms-scan-any>
 * ```
 */
@Directive()
export class WithValidators {
  private inputValidators$ = new BehaviorSubject<ValidatorFn[]>([]);
  constructor(private defaultValidators: ValidatorFn[] = []) {}

  @Input() set validators(validators: ValidatorFn[]) {
    this.inputValidators$.next(validators);
  }
  validators$ = combineLatest([
    of(this.defaultValidators),
    this.inputValidators$,
  ]).pipe(map((validators) => validators.flat()));
}
