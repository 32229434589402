import { createAction, props } from '@ngrx/store';
import {REGISTRY_STATUSES} from "../registry-statuses-all.constants";
import {
  RegistryStatusesState
} from "./registry-statuses.reducer";

const typeName = (name: string) => `[${REGISTRY_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getRegistryStatusesTrigger = createAction(typeName('getRegistryStatuses: Trigger'));
const getRegistryStatusesSuccess = createAction(
  typeName('getRegistryStatuses: Success'),
  props<{ data: RegistryStatusesState['data'] }>()
);
const getRegistryStatusesFailure = createAction(
  typeName('getRegistryStatuses: Failure'),
  failureProps
);
const getRegistryStatusesIsFresh = createAction(typeName('getRegistryStatuses: Is Fresh'));
const getRegistryStatusesCheckFreshness = createAction(
  typeName('getRegistryStatuses: Check Freshness')
);

export const registryStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getRegistryStatusesTrigger,
    success: getRegistryStatusesSuccess,
    failure: getRegistryStatusesFailure,
    isFresh: getRegistryStatusesIsFresh,
    checkFreshness: getRegistryStatusesCheckFreshness,
  },
};
