import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { authActions, LoginTriggerPayload } from './store/auth.actions';
import { authSelectors } from './store/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private store: Store) {}

  user$ = this.store.select(authSelectors.user);
  token$ = this.store.select(authSelectors.token);
  loading$ = this.store.select(authSelectors.loading);
  authorized$ = this.store.select(authSelectors.authorized);
  fullName$ = this.store.select(authSelectors.fullName);
  workPlace$ = this.store.select(authSelectors.workPlace);
  department$ = this.store.select(authSelectors.department);
  tokenExpires$ = this.store.select(authSelectors.tokenExpires);

  setPlace(place: { name: string; id: number }) {
    this.store.dispatch(authActions.setPlace({ place }));
  }
  login(data: LoginTriggerPayload) {
    this.store.dispatch(authActions.login.trigger(data));
  }
  logout() {
    this.store.dispatch(authActions.logout());
  }
}
