import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import { FormAllErrorsComponent } from '../form-errors';

type Entity = { id: number; name: string };
interface Queried<T> {
  data$: Observable<T>;
  loading$: Observable<boolean>;
  updatedAt$: Observable<number>;
  refresh: () => void;
}

type FormValue = {
  userId: number;
};

type TypedFormGroup = FormGroup<{
  [K in keyof FormValue]: FormControl<FormValue[K] | null>;
}>;

@Component({
  selector: 'forms-assign-user',
  styles: [
    `
      .mat-mdc-form-field {
        display: block;
        width: 100%;
      }

      forms-all-errors {
        margin-top: 1rem;
      }

      .actions {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
  template: `
    <form [formGroup]="form" (ngSubmit)="save()">
      <ng-template #selectOptionLoading>
        <span
          style="display: flex; align-items: center; justify-content: space-between"
        >
          Загрузка
          <mat-spinner diameter="20" style="display: inline-block">
          </mat-spinner>
        </span>
      </ng-template>

      <mat-form-field>
        <mat-select
          [placeholder]="FIELD_LABELS.userId"
          formControlName="userId"
        >
          <mat-option>
            <ngx-mat-select-search
              [formControl]="userIdSearchControl"
              *ngIf="(usersDictionary.data$ | async)?.length"
            >
            </ngx-mat-select-search>
          </mat-option>

          <mat-option *ngIf="usersDictionary.loading$ | async as isLoading">
            <ng-container
              *ngTemplateOutlet="selectOptionLoading"
            ></ng-container>
          </mat-option>

          <ng-container *ngIf="usersDictionary.data$ | async as options">
            <mat-option
              *ngFor="let item of userIdsWithSearch$ | async"
              [value]="item.id"
            >
              {{ item.name }}
            </mat-option>

            <mat-option
              *ngIf="
                (usersDictionary.loading$ | async) === false &&
                (usersDictionary.updatedAt$ | async) &&
                !options?.length
              "
            >
              Пусто
            </mat-option>

            <mat-option
              *ngIf="
                (usersDictionary.loading$ | async) === false &&
                (usersDictionary.updatedAt$ | async) === 0 &&
                !options?.length
              "
              ripple
            >
              Не удалось получить список.
              <button
                type="button"
                mat-stroked-button
                (click)="usersDictionary.refresh()"
              >
                Попробовать ещё раз
              </button>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <forms-all-errors
        [form]="form"
        [fields]="FIELD_LABELS"
      ></forms-all-errors>

      <div class="actions">
        <button
          type="button"
          mat-raised-button
          (click)="close()"
          [disabled]="loading"
        >
          Назад
        </button>

        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="form.invalid || loading"
        >
          <mat-spinner
            *ngIf="loading"
            diameter="20"
            style="display: inline-block"
          ></mat-spinner>
          <span>{{ submitButton }}</span>
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormAllErrorsComponent,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    NgxTrimDirectiveModule,
    NgxMatSelectSearchModule,
  ],
})
export class AssignUserComponent implements OnDestroy, OnInit {
  form = this.createForm();
  userIdSearchControl = this.fb.control<string>('');
  userIdSearchControlValue$ = this.userIdSearchControl.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged()
  );
  submitButton = '';

  FIELD_LABELS: Record<keyof FormValue, string> = {
    userId: 'Пользователь',
  };

  @Input() loading: boolean | null = false;
  @Input() initialUserId: number | undefined;
  @Output() done = new EventEmitter<number | undefined>();

  @Input() usersDictionary!: Queried<Entity[]>;
  userIdsWithSearch$ = new BehaviorSubject<Entity[]>([]);

  destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  private createForm(): TypedFormGroup {
    return this.fb.group({
      userId: this.fb.control<number | null>(null, Validators.required),
    });
  }

  ngOnInit() {
    this.submitButton = this.initialUserId ? 'Переназначить' : 'Назначить';
    if (this.initialUserId) {
      this.form.patchValue({ userId: this.initialUserId });
    }

    combineLatest([this.usersDictionary.data$, this.userIdSearchControlValue$])
      .pipe(
        takeUntil(this.destroyed$),
        map(([arr, search]) =>
          !search
            ? arr
            : arr.filter((item) =>
                item.name.toLowerCase().includes(search.toLowerCase())
              )
        )
      )
      .subscribe(this.userIdsWithSearch$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    const value = this.form.getRawValue();
    if (this.form.invalid || !value.userId) return;
    this.done.emit(value.userId);
  }

  close() {
    this.done.emit(undefined);
  }
}
