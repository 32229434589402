import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEFECT_ELIMINATIONS } from '../defect-eliminations.constants';
import { DefectEliminationsState } from './defect-eliminations.reducer';

const state = createFeatureSelector<DefectEliminationsState>(
  DEFECT_ELIMINATIONS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const defectEliminationsSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
