import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthStoreModule, authGuard, notAuthGuard } from '@monorepo/auth/ui';

const routes: Routes = [
  {
    path: 'auth',
    title: 'Авторизация',
    loadComponent: () =>
      import('@monorepo/auth/ui').then((m) => m.AuthComponent),
    canActivate: [notAuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
    canActivate: [authGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    AuthStoreModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
