import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INVENTORY_TYPES } from '../inventory-types.constants';
import { InventoryTypesState } from './inventory-types.reducer';

const state = createFeatureSelector<InventoryTypesState>(
  INVENTORY_TYPES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const inventoryTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
