import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RegistryStatusesState
} from "./registry-statuses.reducer";
import {REGISTRY_STATUSES} from "../registry-statuses-all.constants";

const state = createFeatureSelector<RegistryStatusesState>(REGISTRY_STATUSES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const registryStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
