import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InventoryTypesEffects } from './store/inventory-types.effects';
import { INVENTORY_TYPES } from './inventory-types.constants';
import { inventoryTypesReducer } from './store/inventory-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(INVENTORY_TYPES.FEATURE_NAME, inventoryTypesReducer),
    EffectsModule.forFeature([InventoryTypesEffects]),
  ],
})
export class InventoryTypesModule {}
