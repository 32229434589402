import { inject } from '@angular/core';
import { CanActivateChildFn } from '@angular/router';
import { map, switchMap, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { Dialog } from '@angular/cdk/dialog';
import { ChangePlaceDialogComponent } from '../dialogs/change-place-dialog.component';

export const AllowedPlaces = Symbol('AllowedPlaces');
type TAllowedPlaces = string[];

export const placeGuard: CanActivateChildFn = (route) => {
  const routeConfig = route.routeConfig;
  const authService = inject(AuthService);
  const dialog = inject(Dialog);
  const allowedPlaces: TAllowedPlaces = [
    ...(routeConfig?.data?.[AllowedPlaces] || []),
    ...(routeConfig?.data?.['allowedPlaces'] || []),
  ];

  const process = routeConfig?.title?.toString();
  return authService.workPlace$.pipe(
    map((workPlace) => workPlace?.name),
    map(
      (name) =>
        allowedPlaces.length === 0 ||
        allowedPlaces.some((place) => name?.includes(place))
    ),
    switchMap((allowed) => {
      return allowed
        ? of(true)
        : dialog
            .open<boolean>(ChangePlaceDialogComponent, {
              data: {
                allowedPlaces,
                process,
              },
            })
            .closed.pipe(map(Boolean));
    })
  );
};
