import { createReducer, on } from '@ngrx/store';
import { DefectComentDto } from '@monorepo/dictionaries/dto';
import { defectCommentsActions } from './defect-comments.actions';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';

export type DefectCommentsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: DefectComentDto[];
};

export const initialDefectCommentsState: DefectCommentsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.defectComments,
  data: [],
};

export const defectCommentsReducer = createReducer(
  initialDefectCommentsState,

  // state
  on(defectCommentsActions.state.clear, () => ({
    ...initialDefectCommentsState,
  })),

  // getData
  on(defectCommentsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(defectCommentsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(defectCommentsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
