import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import { inventoryStatusesActions } from './inventory-statuses.actions';

export type InventoryStatusesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: any[];
};

export const initialInventoryStatusesState: InventoryStatusesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.inventoryStatuses,
  data: [],
};

export const inventoryStatusesReducer = createReducer(
  initialInventoryStatusesState,

  // state
  on(inventoryStatusesActions.state.clear, () => ({
    ...initialInventoryStatusesState,
  })),

  // getData
  on(inventoryStatusesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(inventoryStatusesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(inventoryStatusesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
