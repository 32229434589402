import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { usersActions } from './users.actions';
import { usersSelectors } from './users.selectors';

// also facades can be used instead of effects
// https://gist.github.com/ThomasBurleson/38d067abad03b56f1c9caf28ff0f4ebd
@Injectable({
  providedIn: 'root',
})
export class UsersFacade {
  loading$ = this.store.select(usersSelectors.loading);
  updatedAt$ = this.store.select(usersSelectors.updatedAt);
  data$ = this.store.select(usersSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(usersActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(usersActions.getData.trigger());
  }
}
