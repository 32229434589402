import { createAction, props } from '@ngrx/store';
import { MESSAGE_STATUSES } from '../message-statuses.constant';
import { MessageStatusesState } from './message-statuses.reducer';

const typeName = (name: string) => `[${MESSAGE_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getMessageStatusesTrigger = createAction(typeName('getMessageStatuses: Trigger'));
const getMessageStatusesSuccess = createAction(
  typeName('getMessageStatuses: Success'),
  props<{ data: MessageStatusesState['data'] }>()
);
const getMessageStatusesFailure = createAction(
  typeName('getMessageStatuses: Failure'),
  failureProps
);
const getMessageStatusesIsFresh = createAction(typeName('getMessageStatuses: Is Fresh'));
const getMessageStatusesCheckFreshness = createAction(
  typeName('getMessageStatuses: Check Freshness')
);

export const messageStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getMessageStatusesTrigger,
    success: getMessageStatusesSuccess,
    failure: getMessageStatusesFailure,
    isFresh: getMessageStatusesIsFresh,
    checkFreshness: getMessageStatusesCheckFreshness,
  },
};
