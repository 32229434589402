import type { ConditionDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { conditionsActions } from './conditions.actions';

// todo: migrate to dictionaries lib, change cacheTime to DictionariesCacheTime
export type ConditionsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: ConditionDto[];
};

export const initialConditionsState: ConditionsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.conditions,
  data: [],
};

export const conditionsReducer = createReducer(
  initialConditionsState,

  // state
  on(conditionsActions.state.clear, () => ({ ...initialConditionsState })),

  // getConditions
  on(conditionsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(conditionsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(conditionsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
