import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthService } from '../auth.service';

export enum DEPARTMENTS {
  warehouseManagement = 1,
  repairMaster,
  admin,
  shiftSupervisor = 9,
}

export const authGuard = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  return authService.authorized$.pipe(
    map((loggedIn) => loggedIn || createUrlTreeFromSnapshot(route, ['/auth']))
  );
};

export const notAuthGuard = (
  route: ActivatedRouteSnapshot
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  return authService.authorized$.pipe(
    map((loggedIn) => !loggedIn || createUrlTreeFromSnapshot(route, ['/']))
  );
};

export const roleGuard =
  (roles: DEPARTMENTS[]) => (): Observable<boolean | UrlTree> => {
    const authService = inject(AuthService);
    return authService.department$.pipe(
      take(1),
      map((dep) => (dep ? roles.includes(dep.id) : false))
    );
  };
