import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ZonesForLockEffects } from './store/zones-for-lock.effects';
import { ZONES_FOR_LOCK } from './zones-for-lock.constants';
import { zonesForLockReducer } from './store/zones-for-lock.reducer';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ZONES_FOR_LOCK.FEATURE_NAME, zonesForLockReducer),
    EffectsModule.forFeature([ZonesForLockEffects]),
    RouterModule.forChild([]),
  ],
})
export class ZonesForLockModule {}
