import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {
  movementStatusesReducer
} from "./store/movement-statuses.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  MovementStatusesEffects
} from "./store/movement-statuses.effects";
import {
  MOVEMENT_DOC_STATUSES
} from "./statuses-all.constants";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MOVEMENT_DOC_STATUSES.FEATURE_NAME, movementStatusesReducer),
    EffectsModule.forFeature([MovementStatusesEffects])
  ]
})
export class StatusesAllModule {}
