import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDER_STATUSES } from '../order-statuses.constants';
import { OrderStatusesState } from './order-statuses.reducer';

const state = createFeatureSelector<OrderStatusesState>(ORDER_STATUSES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const orderStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
