import { ActionCreatorProps, NotAllowedCheck, ActionCreator, createAction, Action } from '@ngrx/store';

class ActionsBulk {}

interface GetNameFunction {
  (name: string, key: string): string;
}
type ActionName<A extends string, B extends string> = `${A}: ${B}`;
const getName = <A extends string, B extends string>(
  a: A,
  b: B
): ActionName<A, B> => {
  return `${a}: ${b}`;
};

type CreateActionsBulkInputObject<Obj> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof Obj]: Obj[K] extends CreateActionsBulkReturn<infer _A, infer _B> // check if nested action bulk
    ? Obj[K]
    : Obj[K] extends ActionCreatorProps<infer P> & NotAllowedCheck<infer P> // check if props?
    ? ActionCreatorProps<P>
    : never;
};
type CreateActionsBulkReturn<Name extends string, Obj> = {
  [K in keyof Obj]: K extends string
    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Obj[K] extends CreateActionsBulkReturn<infer _A, infer _B> // check if nested action bulk
      ? Obj[K]
      : // check if props
      Obj[K] extends ActionCreatorProps<infer P> & NotAllowedCheck<infer P>
      ? ActionCreator<
          ActionName<Name, K>,
          (
            props: P & NotAllowedCheck<P>
          ) => P & Action<ActionName<Name, K>>
        >
      : Obj[K] extends undefined | null
      ? ActionCreator<
          ActionName<Name, K>,
          () => Action<ActionName<Name, K>>
        >
      : never
    : never;
};

/**
 * ```ts
 * const { trigger, success, failure } = createActionsPlate(actionName, {
 *   trigger: undefined,
 *   success: props<{ a: string }>(),
 *   failure: props<{ error: string }>(),
 * });
 *
 * trigger // () => ({ type })
 * success // (props2) => Object.assign(Object.assign({}, props2), { type })
 * ```
 */
export function createActionsBulk<
  Name extends string,
  A,
  Obj extends CreateActionsBulkInputObject<A>,
  Fn extends GetNameFunction
>(name: Name, a: Obj, getNameFn?: Fn): CreateActionsBulkReturn<Name, Obj> {
  return Object.keys(a).reduce((acc, key) => {
    const plateName = getNameFn?.(name, key) || getName(name, key);
    const propsOrUndefined = a[key as keyof Obj];

    if (
      (propsOrUndefined as ActionCreatorProps<unknown> | undefined)?._as ===
      'props'
    ) {
      // @ts-expect-error acc[key] exists
      acc[key] = createAction(plateName, propsOrUndefined);
    } else if (propsOrUndefined instanceof ActionsBulk) {
      // @ts-expect-error acc[key] exists
      acc[key] = propsOrUndefined;
    } else if (propsOrUndefined === undefined || propsOrUndefined === null) {
      // @ts-expect-error acc[key] exists
      acc[key] = createAction(plateName);
    } else {
      throw new Error(
        "Can't create action bulk. Props must be object with: nested action bulks, props fn, undefined"
      );
    }

    return acc;
  }, new ActionsBulk() as CreateActionsBulkReturn<Name, Obj>);
}

createActionsBulk.getName = getName;
