import {createFeatureSelector, createSelector} from "@ngrx/store";
import { LIST_GROUPS } from "../list-groups-all.constant";
import { ListGroupsState } from "./list-groups.reducer";

const state = createFeatureSelector<ListGroupsState>(LIST_GROUPS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const listGroupsSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
