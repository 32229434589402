import { BehaviorSubject, Observable, tap } from 'rxjs';

export interface QueriedFunction<Args extends unknown[], B> {
  (...a: Args): Observable<B>;
  loading$: Observable<boolean>;
}
export function restQuery<Args extends unknown[], B>(
  cb: (...a: Args) => Observable<B>
): QueriedFunction<Args, B> {
  const loading$ = new BehaviorSubject(false);

  return Object.assign(
    (...a: Args) => {
      const observable = cb(...a);

      return observable.pipe(
        tap({
          subscribe: () => loading$.next(true),
          finalize: () => loading$.next(false),
        })
      );
    },
    { loading$: loading$.asObservable() }
  );
}
