import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { stringifyError } from '../../validators/errors';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'forms-all-errors',
  styleUrl: './form-errors.component.scss',
  template: `
    <div class="errors">
      <ng-container *ngFor="let field of fields | keyvalue: originalOrder">
        <ng-container
          *ngIf="
            form.controls[field.key]?.touched &&
            form.controls[field.key].errors as errors
          "
        >
          <div class="error">
            {{ field.value }}:
            <span *ngIf="showOnlyFirstControlError">
              {{ getControlErrors(errors)[0] }}
            </span>
            <span *ngIf="!showOnlyFirstControlError">
              {{ getControlErrors(errors).join(', ') }}
            </span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
})
export class FormAllErrorsComponent {
  @Input()
  form!: UntypedFormGroup;

  @Input()
  fields!: Record<string, string>;

  @Input()
  skipFields: string[] = [];

  @Input()
  showOnlyFirstControlError = false;

  getControlErrors(controlErrors: ValidationErrors) {
    const errors: string[] = [];

    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach((key) => {
        const stringifiedError = stringifyError({
          key,
          value: controlErrors[key],
        });

        const canBePushed = stringifiedError && !this.skipFields.includes(key);

        if (canBePushed) {
          errors.push(stringifiedError);
        }
      });
    }

    return errors;
  }

  // for keyvalue pipe angular
  // https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  originalOrder = (a: unknown, b: unknown): number => {
    return 0;
  };
}
