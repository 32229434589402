<form [formGroup]="form" (submit)="onSubmit()" class="w-full">
  <div *ngIf="showSelect()" class="select__wrapper">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Возможные размеры</mat-label>
      <mat-select [formControl]="selectControl">
        <mat-option *ngFor="let size of availableSizes" [value]="size.id">
          {{ size.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-icon
      class="tooltip"
      *ngIf="(isMobile$ | async) !== true"
      matTooltip="Для навигации между полями можно использовать TAB (и SHIFT + TAB)"
    >
      help_outlined
    </mat-icon>
  </div>

  <div class="dims">
    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>{{ FIELD_LABELS.length }}</mat-label>
      <input matInput type="text" formControlName="length" />
    </mat-form-field>

    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>{{ FIELD_LABELS.width }}</mat-label>
      <input matInput type="text" formControlName="width" />
    </mat-form-field>

    <mat-form-field class="example-form-field" appearance="fill">
      <mat-label>{{ FIELD_LABELS.height }}</mat-label>
      <input matInput type="text" formControlName="height" />
    </mat-form-field>
  </div>

  <button
    class="w-full"
    mat-raised-button
    color="primary"
    [disabled]="form.invalid || loading"
  >
    <mat-spinner
      *ngIf="loading"
      diameter="20"
      style="display: inline-block"
    ></mat-spinner>
    Подтвердить
  </button>
</form>
