import { createAction, props } from '@ngrx/store';
import { DEFECT_ELIMINATIONS } from '../defect-eliminations.constants';
import { DefectEliminationsState } from './defect-eliminations.reducer';

const typeName = (name: string) =>
  `[${DEFECT_ELIMINATIONS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getDefectEliminationsTrigger = createAction(
  typeName('getDefectEliminations: Trigger')
);
const getDefectEliminationsSuccess = createAction(
  typeName('getDefectEliminations: Success'),
  props<{ data: DefectEliminationsState['data'] }>()
);
const getDefectEliminationsFailure = createAction(
  typeName('getDefectEliminations: Failure'),
  failureProps
);
const getDefectEliminationsIsFresh = createAction(
  typeName('getDefectEliminations: Is Fresh')
);
const getDefectEliminationsCheckFreshness = createAction(
  typeName('getDefectEliminations: Check Freshness')
);

export const defectEliminationsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getDefectEliminationsTrigger,
    success: getDefectEliminationsSuccess,
    failure: getDefectEliminationsFailure,
    isFresh: getDefectEliminationsIsFresh,
    checkFreshness: getDefectEliminationsCheckFreshness,
  },
};
