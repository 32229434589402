import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductDefectsEffects } from './store/product-defects.effects';
import { PRODUCT_DEFECTS } from './product-defects.constants';
import { productDefectsReducer } from './store/product-defects.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PRODUCT_DEFECTS.FEATURE_NAME, productDefectsReducer),
    EffectsModule.forFeature([ProductDefectsEffects]),
    RouterModule.forChild([]),
  ],
})
export class ProductDefectsModule {}
