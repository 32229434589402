import { createAction, props } from '@ngrx/store';
import { USERS } from '../users.constants';
import { UsersState } from './users.reducer';

const typeName = (name: string) => `[${USERS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getUsersTrigger = createAction(typeName('getUsers: Trigger'));
const getUsersSuccess = createAction(
  typeName('getUsers: Success'),
  props<{ data: UsersState['data'] }>()
);
const getUsersFailure = createAction(
  typeName('getUsers: Failure'),
  failureProps
);
const getUsersIsFresh = createAction(typeName('getUsers: Is Fresh'));
const getUsersCheckFreshness = createAction(
  typeName('getUsers: Check Freshness')
);

export const usersActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getUsersTrigger,
    success: getUsersSuccess,
    failure: getUsersFailure,
    isFresh: getUsersIsFresh,
    checkFreshness: getUsersCheckFreshness,
  },
};
