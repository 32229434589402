import {createReducer, on} from "@ngrx/store";
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import {MovementDocumentObjectDto} from "@monorepo/dictionaries/dto";
import {
  movementDocObjectsActions
} from "./movement-object.actions";

export type MovementDocObjectsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: MovementDocumentObjectDto[];
};
export const initialMovementDocObjectsState: MovementDocObjectsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.movementDocObjects,
  data: [],
};

export const movementObjectReducer = createReducer(
  initialMovementDocObjectsState,

  // state
  on(movementDocObjectsActions.state.clear, () => ({ ...initialMovementDocObjectsState })),

  // getUsers
  on(movementDocObjectsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(movementDocObjectsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(movementDocObjectsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
