import {Injectable} from "@angular/core";
import {
  RegistryStatusesHttpService
} from "../registry-statuses-http.service";

@Injectable({
  providedIn: 'root',
})
export class RegistryStatusesAllService {
  constructor(private registryStatusesHttpService: RegistryStatusesHttpService) {
  }

  getData() {
    return this.registryStatusesHttpService.getAll();
  }
}
