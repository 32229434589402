import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { inventoryTypesActions } from './inventory-types.actions';
import { inventoryTypesSelectors } from './inventory-types.selectors';
import { InventoryTypesService } from '../inventory-types.service';
import { isFresh } from '@monorepo/ui/helpers';

@Injectable()
export class InventoryTypesEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(inventoryTypesActions.getData.trigger),
      exhaustMap(() => {
        return this.inventoryTypesService.getData().pipe(
          map((data) => {
            return inventoryTypesActions.getData.success({ data });
          }),
          catchError((error) => {
            return of(inventoryTypesActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(inventoryTypesActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(inventoryTypesSelectors.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? inventoryTypesActions.getData.isFresh()
          : inventoryTypesActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(inventoryTypesActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({
            error,
          });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private inventoryTypesService: InventoryTypesService
  ) {}
}
