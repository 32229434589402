import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  setItem(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): unknown {
    return JSON.parse(localStorage.getItem(key) || '{}');
  }
}
