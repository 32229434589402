import { createAction, props } from '@ngrx/store';
import {TRANSPORT_COMPANIES_FOR_INTEGRATION} from "../tc-for-integration.constants";
import {
  TcForIntegrationState
} from "./transport-companies-for-integration.reducer";

const typeName = (name: string) => `[${TRANSPORT_COMPANIES_FOR_INTEGRATION.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getTcTrigger = createAction(
  typeName('getTc: Trigger'),
);
const getTcSuccess = createAction(
  typeName('getTc: Success'),
  props<{ data: TcForIntegrationState['data'] }>()
);
const getTcFailure = createAction(
  typeName('getTc: Failure'),
  failureProps
);
const getTcIsFresh = createAction(
  typeName('getTc: Is Fresh')
);
const getTcCheckFreshness = createAction(
  typeName('getTc: Check Freshness'),
);

export const transportCompaniesForIntegrationActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getTcTrigger,
    success: getTcSuccess,
    failure: getTcFailure,
    isFresh: getTcIsFresh,
    checkFreshness: getTcCheckFreshness,
  },
};
