import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZONES_ALL } from '../zones-all.constants';
import { ZonesAllState } from './zones-all.reducer';

const state = createFeatureSelector<ZonesAllState>(ZONES_ALL.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const zonesAllSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
