import { OrderTypeDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { orderTypesActions } from './order-types.actions';

export type OrderTypesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: OrderTypeDto[];
};

export const initialOrderTypesState: OrderTypesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: 12 * 60 * 60 * 1000,
  data: [],
};

export const orderTypesReducer = createReducer(
  initialOrderTypesState,

  // state
  on(orderTypesActions.state.clear, () => ({ ...initialOrderTypesState })),

  // getData
  on(orderTypesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(orderTypesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(orderTypesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
