import {inject, Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {PrintFormsDictionaryState} from "./print-forms.state";
import {map} from "rxjs/operators";

@Injectable()
export class PrintFormsDictionaryFacade {
  private store = inject(Store);
  public items$ = this.store.select(PrintFormsDictionaryState.getState).pipe(
    map((state) => state.items)
  )
}
