import {createReducer, on} from "@ngrx/store";
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import {
  transportCompaniesForIntegrationActions
} from "./transport-companies-for-integration.actions";
import {TransportCompanyDto} from "@monorepo/dictionaries/dto";

export type TcForIntegrationState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: TransportCompanyDto[];
};
export const initialTcForIntegrationState: TcForIntegrationState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.transportCompanies,
  data: [],
};

export const transportCompaniesForIntegrationReducer = createReducer(
  initialTcForIntegrationState,

  // state
  on(transportCompaniesForIntegrationActions.state.clear, () => ({ ...initialTcForIntegrationState })),

  // getUsers
  on(transportCompaniesForIntegrationActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(transportCompaniesForIntegrationActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(transportCompaniesForIntegrationActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
