import { ThemeService } from '@12storeez/angular/theme';
import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject, bufferCount, filter, take } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService } from '@monorepo/auth/ui';
import {
  PwaService,
  PwaUpdateDialogService,
} from '@monorepo/ui/services/browser';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'monorepo-root',
  template: `
    <monorepo-styles-provider></monorepo-styles-provider>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    private pwaUpdateDialogService: PwaUpdateDialogService,
    private pwaService: PwaService,
    private translateService: TranslateService,
    private themeService: ThemeService, // need to init theme service
    private authService: AuthService,
    private config: PrimeNGConfig,
    private router: Router
  ) {}

  updateTimeout$ = new BehaviorSubject<number>(0);

  ngOnInit() {
    this.translate('ru'); // todo: get def lang from env

    /**
     * Set user to sentry
     */
    this.authService.user$.subscribe((user) => {
      const userToSet = user?.id ? { id: user.id.toString() } : null;
      Sentry.setUser(userToSet);
    });

    /**
     * Logout on token expires
     */
    this.authService.tokenExpires$.subscribe((time) => {
      const resetTime = (new Date(time || 0).getTime() || 0) - Date.now();
      setTimeout(() => {
        this.authService.logout();
      }, resetTime);
    });

    /**
     * Fix for placeGuard blocks navigation on initial load
     */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event) => {
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([(<NavigationStart>event).url]);
          });
      });

    /**
     * Check for new version
     */
    this.pwaService.newVersion$.subscribe(() => this.update());

    /**
     * Clear old timeout. Has current and prev. Clear prev on new timeout.
     */
    this.updateTimeout$.pipe(bufferCount(2, 1)).subscribe(([prev]) => {
      clearTimeout(prev);
    });
  }

  private update() {
    this.pwaUpdateDialogService.open({
      data: {
        updateNow: (ref) => {
          ref.close();
          this.pwaService.updateNow();
        },
        updateIn: (ref, milliseconds) => {
          ref.close();
          const timeout = setTimeout(() => this.update(), milliseconds);
          this.updateTimeout$.next(timeout as unknown as number);
        },
      },
    });
  }

  /**
   * Function set app language + primeng language
   * @param {string} lang
   */
  private translate(lang: string) {
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }
}
