import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AcceptanceStatusesEffects } from './store/acceptance-statuses.effects';
import { ACCEPTANCE_STATUSES } from './acceptance-statuses.constants';
import { acceptanceStatusesReducer } from './store/acceptance-statuses.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      ACCEPTANCE_STATUSES.FEATURE_NAME,
      acceptanceStatusesReducer
    ),
    EffectsModule.forFeature([AcceptanceStatusesEffects]),
    RouterModule.forChild([]),
  ],
})
export class AcceptanceStatusesModule {}
