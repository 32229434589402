import { AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Error state matcher that matches when a control is invalid and (dirty or touched).
 * Default behavior - [source](https://github.com/angular/components/blob/15.0.x/src/material/core/error/error-options.ts)
 */
export class DirtyTouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}
