import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { authActions } from './auth.actions';
import { AuthHttpService } from '../auth-http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { AuthDataAdapterService } from '../auth-data-adapter.service';
import { Router } from '@angular/router';
import { SnackbarService } from '@monorepo/ui/services/snackbar';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.login.trigger),
      exhaustMap(({ type, ...payload }) => {
        return this.authHttpService.login(payload).pipe(
          map((response) => {
            const payload =
              this.authDataAdapterService.httpLoginToState(response);
            return authActions.login.success(payload);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(authActions.login.failure({ error }));
          })
        );
      })
    );
  });

  redirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.login.success, authActions.logout),
        tap(({ type }) => {
          if (type === authActions.login.success.type) {
            this.router.navigateByUrl('/');
          } else {
            this.router.navigateByUrl('/auth');
          }
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.logout),
      map(() => authActions.state.clear())
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authActions.login.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private authHttpService: AuthHttpService,
    private snackbarService: SnackbarService,
    private authDataAdapterService: AuthDataAdapterService
  ) {}
}
