const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

export const DictionariesCacheTime = {
  acceptanceStatuses: 12 * HOUR,
  acceptanceTypes: 12 * HOUR,
  businessPartners: 12 * HOUR,
  conditions: 12 * HOUR,
  departments: 12 * HOUR,
  defectComments: 12 * HOUR,
  defectEliminations: 12 * HOUR,
  productDefects: 12 * HOUR,
  productZones: 12 * HOUR,
  tradeItemGroups: 12 * HOUR,
  usersMasters: 12 * HOUR,

  offSystemSteps: 12 * HOUR,

  zones: 12 * HOUR,
  zonesInventory: 12 * HOUR,
  zonesForLock: 12 * HOUR,

  placeTypes: 12 * HOUR,

  users: 12 * HOUR,
  supervisors: 12 * HOUR,
  transportCompanies: 12 * HOUR,
  movementDocStatuses: 12 * HOUR,
  movementDocObjects: 12 * HOUR,
  mistakes: 12 * HOUR,

  inventoryStatuses: 12 * HOUR,
  inventoryTypes: 12 * HOUR,
  inventoryCountingTypes: 12 * HOUR,

  registryTypes: 12 * HOUR,
  registryStatuses: 12 * HOUR,

  messageStatuses: 12 * HOUR,
};
