import { createReducer, on } from '@ngrx/store';
import { ssccTypesActions } from './sscc-types.actions';

export type SsccTypesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  // todo: real dto
  data: any[];
};

export const initialSsccTypesState: SsccTypesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: 12 * 60 * 60 * 1000,
  data: [],
};

export const ssccTypesReducer = createReducer(
  initialSsccTypesState,

  // state
  on(ssccTypesActions.state.clear, () => ({ ...initialSsccTypesState })),

  // getData
  on(ssccTypesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(ssccTypesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(ssccTypesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
