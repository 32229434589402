import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InventoryStatusesEffects } from './store/inventory-statuses.effects';
import { INVENTORY_STATUSES } from './inventory-statuses.constants';
import { inventoryStatusesReducer } from './store/inventory-statuses.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      INVENTORY_STATUSES.FEATURE_NAME,
      inventoryStatusesReducer
    ),
    EffectsModule.forFeature([InventoryStatusesEffects]),
  ],
})
export class InventoryStatusesModule {}
