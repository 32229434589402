import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { ssccTypesActions } from './sscc-types.actions';
import { ssccTypesSelectors } from './sscc-types.selectors';

@Injectable({
  providedIn: 'root',
})
export class SsccTypesFacade {
  loading$ = this.store.select(ssccTypesSelectors.loading);
  updatedAt$ = this.store.select(ssccTypesSelectors.updatedAt);
  data$ = this.store.select(ssccTypesSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(ssccTypesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(ssccTypesActions.getData.trigger());
  }
}
