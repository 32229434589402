import {NgModule} from "@angular/core";
import {PlacesService} from "./places.service";
import {PlacesDictionaryFacade} from "./state/places.facade";
import {provideStates} from "@ngxs/store";
import {PlacesDictionaryState} from "./state/places.state";

@NgModule({
  providers: [
    PlacesService,
    PlacesDictionaryFacade,
    provideStates([PlacesDictionaryState])
  ],
})
export class PlacesDictionaryModule {}
