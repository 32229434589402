import { Injectable } from '@angular/core';
import { InventoryCountingTypesFacade } from './inventory-counting-types';
import { InventoryListStatusesFacade } from './inventory-list-statuses';
import { InventoryStatusesFacade } from './inventory-statuses';
import { InventoryTypesFacade } from './inventory-types';

@Injectable({ providedIn: 'root' })
export class InventoryDictionariesService {
  constructor(
    public types: InventoryTypesFacade,
    public statuses: InventoryStatusesFacade,
    public countingTypes: InventoryCountingTypesFacade,
    public listStatuses: InventoryListStatusesFacade
  ) {}
}
