import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { restQuery } from '@monorepo/ui/helpers';
import { API_URL } from '@monorepo/ui/core';
import { PlaceDto } from '@monorepo/topology/dto';

@Injectable({
  providedIn: 'root',
})
export class AccountHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  getPlace = restQuery((place: string) => {
    const URL = `${this.apiUrl}/api/v3/topology/place/name/${place}`; // todo: to sac
    return this.http.get<PlaceDto>(URL);
  });
}
