import { createAction, props } from '@ngrx/store';
import { INVENTORY_COUNTING_TYPES } from '../inventory-counting-types.constants';
import { InventoryCountingTypesState } from './inventory-counting-types.reducer';

const typeName = (name: string) =>
  `[${INVENTORY_COUNTING_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getInventoryCountingTypesTrigger = createAction(
  typeName('getInventoryCountingTypes: Trigger')
);
const getInventoryCountingTypesSuccess = createAction(
  typeName('getInventoryCountingTypes: Success'),
  props<{ data: InventoryCountingTypesState['data'] }>()
);
const getInventoryCountingTypesFailure = createAction(
  typeName('getInventoryCountingTypes: Failure'),
  failureProps
);
const getInventoryCountingTypesIsFresh = createAction(
  typeName('getInventoryCountingTypes: Is Fresh')
);
const getInventoryCountingTypesCheckFreshness = createAction(
  typeName('getInventoryCountingTypes: Check Freshness')
);

export const inventoryCountingTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getInventoryCountingTypesTrigger,
    success: getInventoryCountingTypesSuccess,
    failure: getInventoryCountingTypesFailure,
    isFresh: getInventoryCountingTypesIsFresh,
    checkFreshness: getInventoryCountingTypesCheckFreshness,
  },
};
