import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { WithValidators } from '../shared/with-validators';
import { CUSTOM_VALIDATORS_PRESETS } from '../../validators/custom-validators';
import { ScanFieldComponent } from '../scan-field/scan-field.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'forms-scan-sku',
  template: `<forms-base-scan-field
    [label]="label"
    [placeholder]="placeholder"
    [validators]="validators$ | async"
    [disabled]="disabled"
    [loading]="loading"
    [reset$]="reset$"
    (formSubmit)="onFormSubmit($event)"
  ></forms-base-scan-field>`,
})
export class ScanSkuComponent extends WithValidators {
  constructor() {
    super(CUSTOM_VALIDATORS_PRESETS.sku);
  }

  @Input() label = 'Отсканируйте штрих-код товара';
  @Input() placeholder = 'ШК';

  @Input() reset$?: Observable<unknown>;
  @Input() loading?: boolean | null = null;
  @Input() disabled?: boolean | null = null;
  @Output() formSubmit = new EventEmitter<string>();
  @ViewChild(ScanFieldComponent) baseField?: ScanFieldComponent;

  onFormSubmit(scanText: string) {
    this.formSubmit.emit(scanText);
  }
}
