import {Injectable} from "@angular/core";
import { MistakesHttpService } from "../mistakes-http.service";

@Injectable({
  providedIn: 'root',
})
export class MistakesAllService {
  constructor(private mistakesHttpService: MistakesHttpService) {
  }

  getData() {
    return this.mistakesHttpService.getAll();
  }
}
