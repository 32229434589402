import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ZonesInventoryEffects } from './store/zones-inventory.effects';
import { ZONES_INVENTORY } from './zones-inventory.constants';
import { zonesInventoryReducer } from './store/zones-inventory.reducer';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ZONES_INVENTORY.FEATURE_NAME, zonesInventoryReducer),
    EffectsModule.forFeature([ZonesInventoryEffects]),
    RouterModule.forChild([]),
  ],
})
export class ZonesInventoryModule {}
