import {RegistryTypesHttpService} from "../registry-types-http.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class RegistryTypesAllService {
  constructor(private registryTypesHttpService: RegistryTypesHttpService) {
  }

  getData() {
    return this.registryTypesHttpService.getAll();
  }
}
