import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';
import { AUTH } from './auth.constants';
import { authReducer } from './store/auth.reducers';
import { NgModule } from '@angular/core';
import { LocalStorageService } from '@monorepo/ui/services/browser';
import {
  AUTH_STORAGE_KEY,
  AUTH_META_REDUCERS,
  AUTH_SAVE_KEYS,
} from './auth-tokens';
import { getMetaReducerConfig } from '@monorepo/ui/core';

@NgModule({
  providers: [
    { provide: AUTH_STORAGE_KEY, useValue: 'store.' + AUTH.FEATURE_NAME },
    { provide: AUTH_SAVE_KEYS, useValue: undefined },
    {
      provide: AUTH_META_REDUCERS,
      deps: [AUTH_STORAGE_KEY, LocalStorageService, AUTH_SAVE_KEYS],
      useFactory: getMetaReducerConfig,
    },
  ],
  imports: [
    StoreModule.forFeature(AUTH.FEATURE_NAME, authReducer, AUTH_META_REDUCERS),
    EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [StoreModule, EffectsModule],
})
export class AuthStoreModule {}
