import { createAction, props } from '@ngrx/store';
import { ORDER_TYPES } from '../order-types.constants';
import { OrderTypesState } from './order-types.reducer';

const typeName = (name: string) => `[${ORDER_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getOrderTypesTrigger = createAction(typeName('getOrderTypes: Trigger'));
const getOrderTypesSuccess = createAction(
  typeName('getOrderTypes: Success'),
  props<{ data: OrderTypesState['data'] }>()
);
const getOrderTypesFailure = createAction(
  typeName('getOrderTypes: Failure'),
  failureProps
);
const getOrderTypesIsFresh = createAction(typeName('getOrderTypes: Is Fresh'));
const getOrderTypesCheckFreshness = createAction(
  typeName('getOrderTypes: Check Freshness')
);

export const orderTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getOrderTypesTrigger,
    success: getOrderTypesSuccess,
    failure: getOrderTypesFailure,
    isFresh: getOrderTypesIsFresh,
    checkFreshness: getOrderTypesCheckFreshness,
  },
};
