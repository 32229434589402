import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { map, ReplaySubject, shareReplay, takeUntil } from 'rxjs';

import { AuthService } from './auth.service';
import { InputControl } from '@12stz/ui-kit/controls/input';
import { ButtonModule } from '@12stz/ui-kit/atoms/button';
import { LoadingDirective } from '@12stz/ui-kit/quarks/directives';

type FormValue = { login: string; password: string; workplace: string | null };
type TypedFormGroup = FormGroup<{
  [K in keyof FormValue]: FormControl<FormValue[K] | null>;
}>;

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputControl,
    ButtonModule,
    LoadingDirective,
    AsyncPipe,
  ],
})
export class AuthComponent implements OnInit, OnDestroy, AfterViewInit {
  public isHandset$ = this.breakpointObserver
    .observe(Breakpoints.HandsetPortrait)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  public form: TypedFormGroup = this.fb.group({
    login: this.fb.control<string | null>('', Validators.required),
    password: this.fb.control<string | null>('', Validators.required),
    workplace: this.fb.control<string | null>(''),
  });
  public loading$ = this.auth.loading$;
  @ViewChild('login') private loginRef?: InputControl;

  private destroyed$ = new ReplaySubject<void>();

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngAfterViewInit() {
    setTimeout(() =>
      this.loginRef?.focus()
    );
  }

  ngOnInit(): void {
    this.isHandset$.pipe(takeUntil(this.destroyed$)).subscribe((isHandset) => {
      if (isHandset) {
        this.form.controls.workplace.enable();
        this.form.controls.workplace.setValidators(Validators.required);
      } else {
        this.form.controls.workplace.reset();
        this.form.controls.workplace.disable();
        this.form.controls.workplace.clearValidators();
      }
    });
  }

  public onSubmit(): void {
    const { login, password, workplace } = this.form.value;
    if (!login || !password || this.form.invalid) return;
    this.auth.login({ login, password, workplace });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
