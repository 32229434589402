import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MESSAGE_STATUSES } from '../message-statuses.constant';
import { MessageStatusesState } from './message-statuses.reducer';

const state = createFeatureSelector<MessageStatusesState>(MESSAGE_STATUSES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const messageStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
