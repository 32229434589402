import {Injectable} from "@angular/core";
import { ListGroupsHttpService } from "../list-groups-http.service";

@Injectable({
  providedIn: 'root',
})
export class ListGroupsAllService {
  constructor(private listGroupsHttpService: ListGroupsHttpService) {
  }

  getData() {
    return this.listGroupsHttpService.getAll();
  }
}
