import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { orderTypesActions } from './order-types.actions';
import { orderTypesSelectors } from './order-types.selectors';

@Injectable({
  providedIn: 'root',
})
export class OrderTypesFacade {
  loading$ = this.store.select(orderTypesSelectors.loading);
  updatedAt$ = this.store.select(orderTypesSelectors.updatedAt);
  data$ = this.store.select(orderTypesSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(orderTypesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(orderTypesActions.getData.trigger());
  }
}
