import { createAction, props } from '@ngrx/store';
import {REGISTRY_TYPES} from "../registry-types-all.constants";
import {
  RegistryTypesState
} from "./registry-types.reducer";

const typeName = (name: string) => `[${REGISTRY_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getRegistryTypesTrigger = createAction(typeName('getRegistryTypes: Trigger'));
const getRegistryTypesSuccess = createAction(
  typeName('getRegistryTypes: Success'),
  props<{ data: RegistryTypesState['data'] }>()
);
const getRegistryTypesFailure = createAction(
  typeName('getRegistryTypes: Failure'),
  failureProps
);
const getRegistryTypesIsFresh = createAction(typeName('getRegistryTypes: Is Fresh'));
const getRegistryTypesCheckFreshness = createAction(
  typeName('getRegistryTypes: Check Freshness')
);

export const registryTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getRegistryTypesTrigger,
    success: getRegistryTypesSuccess,
    failure: getRegistryTypesFailure,
    isFresh: getRegistryTypesIsFresh,
    checkFreshness: getRegistryTypesCheckFreshness,
  },
};
