import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ZonesAllEffects } from './store/zones-all.effects';
import { ZONES_ALL } from './zones-all.constants';
import { zonesAllReducer } from './store/zones-all.reducer';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ZONES_ALL.FEATURE_NAME, zonesAllReducer),
    EffectsModule.forFeature([ZonesAllEffects]),
    RouterModule.forChild([]),
  ],
})
export class ZonesAllModule {}
