import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs';
import { CUSTOM_VALIDATORS_PRESETS } from '../../validators/custom-validators';
import { ScanFieldComponent } from '../scan-field/scan-field.component';

export enum ScanUiidOrSkuMode {
  UIID = 'UIID',
  SKU = 'SKU',
}

export type ScanUiidOrSkuEvent = {
  value: string;
  mode: ScanUiidOrSkuMode;
};

const validatorsMap = {
  [ScanUiidOrSkuMode.UIID]: CUSTOM_VALIDATORS_PRESETS.uiid,
  [ScanUiidOrSkuMode.SKU]: CUSTOM_VALIDATORS_PRESETS.sku,
};

@Component({
  selector: 'forms-scan-uiid-or-sku',
  template: `<forms-base-scan-field
    [label]="label"
    [placeholder]="placeholder"
    [validators]="validators$ | async"
    [disabled]="disabled"
    [loading]="loading"
    [reset$]="reset$"
    (formSubmit)="onFormSubmit($event)"
    (valueChanges)="onValueChanges($event)"
  ></forms-base-scan-field>`,
})
export class ScanUiidOrSkuComponent implements OnInit, OnDestroy {
  @Input() loading?: boolean | null = null;
  @Input() disabled?: boolean | null = null;
  @Input() label = 'Отсканируйте Item ID или ШК';
  @Input() placeholder = 'Item ID или ШК';

  @Output() mode$ = new BehaviorSubject(ScanUiidOrSkuMode.UIID);
  validators$ = this.mode$.pipe(map((mode) => validatorsMap[mode]));

  @Input() reset$?: Observable<unknown>;
  @Output() formSubmit = new EventEmitter<ScanUiidOrSkuEvent>();
  @Output() uiidSubmit = new EventEmitter<string>();
  @Output() barcodeSubmit = new EventEmitter<string>();
  @ViewChild(ScanFieldComponent) baseField?: ScanFieldComponent;

  mightBeSku$ = new Subject<boolean>();
  destroyed$ = new Subject<void>();

  reset() {
    this.baseField?.reset();
  }

  onFormSubmit(value: string) {
    const mode = this.mode$.getValue();
    if (mode === ScanUiidOrSkuMode.UIID) {
      this.uiidSubmit.emit(value);
    }
    if (mode === ScanUiidOrSkuMode.SKU) {
      this.barcodeSubmit.emit(value);
    }
    this.formSubmit.emit({ value, mode });
  }

  onValueChanges(v = '') {
    this.mightBeSku$.next(v.length > 10);
  }

  ngOnInit() {
    this.mightBeSku$
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
      .subscribe((isSku) => {
        this.mode$.next(isSku ? ScanUiidOrSkuMode.SKU : ScanUiidOrSkuMode.UIID);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
