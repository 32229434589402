import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZONES_INVENTORY } from '../zones-inventory.constants';
import { ZonesInventoryState } from './zones-inventory.reducer';

const state = createFeatureSelector<ZonesInventoryState>(
  ZONES_INVENTORY.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const zonesInventorySelectors = {
  data,
  state,
  loading,
  updatedAt,
};
