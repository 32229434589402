import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { productDefectsActions } from './product-defects.actions';
import { productDefectsSelectors } from './product-defects.selectors';
import { ProductDefectsService } from '../product-defects.service';
import { isFresh } from '@monorepo/ui/helpers';

@Injectable()
export class ProductDefectsEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productDefectsActions.getData.trigger),
      exhaustMap(() => {
        return this.productDefectsService.getData().pipe(
          map((data) => {
            return productDefectsActions.getData.success({ data });
          }),
          catchError((error) => {
            return of(productDefectsActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(productDefectsActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(productDefectsSelectors.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? productDefectsActions.getData.isFresh()
          : productDefectsActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(productDefectsActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private productDefectsService: ProductDefectsService
  ) {}
}
