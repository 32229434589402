import { Injectable } from '@angular/core';
import { TopologyHttpService } from '../topology-http.service';

@Injectable({
  providedIn: 'root',
})
export class SectorsByZoneService {
  constructor(private topologyHttpService: TopologyHttpService) {}

  getData = this.topologyHttpService.getSectorsByZone;
}
