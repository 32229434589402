import { createAction, props } from '@ngrx/store';
import { BUSINESS_PARTNERS } from '../business-partners.constants';
import { BusinessPartnersState } from './business-partners.reducer';

const typeName = (name: string) =>
  `[${BUSINESS_PARTNERS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getBusinessPartnersTrigger = createAction(
  typeName('getBusinessPartners: Trigger')
);
const getBusinessPartnersSuccess = createAction(
  typeName('getBusinessPartners: Success'),
  props<{ data: BusinessPartnersState['data'] }>()
);
const getBusinessPartnersFailure = createAction(
  typeName('getBusinessPartners: Failure'),
  failureProps
);
const getBusinessPartnersIsFresh = createAction(
  typeName('getBusinessPartners: Is Fresh')
);
const getBusinessPartnersCheckFreshness = createAction(
  typeName('getBusinessPartners: Check Freshness')
);

export const businessPartnersActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getBusinessPartnersTrigger,
    success: getBusinessPartnersSuccess,
    failure: getBusinessPartnersFailure,
    isFresh: getBusinessPartnersIsFresh,
    checkFreshness: getBusinessPartnersCheckFreshness,
  },
};
