import { removeColon } from '../fn';

type InputProps = {
  CONTROLLER: { box: string };
  PUT_PLACE: { place: string };
  PUT_PLACE_MOVE: { place: string; type: 'on' | 'off' };
  GET_BARCODE: { barcode: string };
  PUT_UIID: { uiid: string };
  PUT_BARCODE_AMOUNT: { barcode: string; amount: string };
};

export const CONTROL_BOX_API = {
  CONTROLLER: (params: InputProps['CONTROLLER']) => {
    const { box } = params;
    return {
      params: removeColon(params),
      url: `control/box/${box}` as const,
    };
  },

  PUT_START: () => {
    return {
      url: `start` as const,
    };
  },

  PUT_RETRY: () => {
    return {
      url: `retry` as const,
    };
  },

  PUT_RESET: () => {
    return {
      url: `reset` as const,
    };
  },

  PUT_PLACE: (params: InputProps['PUT_PLACE']) => {
    const { place } = params;
    return {
      params: removeColon(params),
      url: `place/${place}` as const,
    };
  },

  PUT_PLACE_MOVE: (params: InputProps['PUT_PLACE_MOVE']) => {
    const { place, type } = params;
    return {
      params: removeColon(params),
      url: `place/${place}/put/${type}` as const,
    };
  },

  GET_BARCODE: (params: InputProps['GET_BARCODE']) => {
    const { barcode } = params;
    return {
      params: removeColon(params),
      url: `barcode/${barcode}` as const,
    };
  },

  PUT_UIID: (params: InputProps['PUT_UIID']) => {
    const { uiid } = params;
    return {
      params: removeColon(params),
      url: `uiid/${uiid}` as const,
    };
  },

  PUT_BARCODE_AMOUNT: (params: InputProps['PUT_BARCODE_AMOUNT']) => {
    const { barcode, amount } = params;
    return {
      params: removeColon(params),
      url: `barcode/${barcode}/amount/${amount}` as const,
    };
  },

  PUT_FINISH: () => {
    return {
      url: `finish` as const,
    };
  },
} as const;
