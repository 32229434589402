import { ZoneFilterDto } from '@monorepo/topology/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import { zonesForLockActions } from './zones-for-lock.actions';

export type ZonesForLockState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: ZoneFilterDto[];
};

export const initialZonesForLockState: ZonesForLockState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.zonesForLock,
  data: [],
};

export const zonesForLockReducer = createReducer(
  initialZonesForLockState,

  // state
  on(zonesForLockActions.state.clear, () => ({
    ...initialZonesForLockState,
  })),

  // getData
  on(zonesForLockActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(zonesForLockActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(zonesForLockActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
