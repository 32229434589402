import {createFeatureSelector, createSelector} from "@ngrx/store";
import {MistakesState} from "./mistakes.reducer";
import {MISTAKES} from "../mistakes-all.constants";

const state = createFeatureSelector<MistakesState>(MISTAKES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const mistakesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
