<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <sz-input
    #scanFieldInput
    class="scan-field-input"
    [tag]="label"
    formControlName="formField"
    [placeholder]="placeholder"
    [ngClass]="{'no-controls': removeControls}"
  >
    @if (form.get('formField')?.value) {
      <sz-icon aria-label="Clear" class="clear-field" szToRight icon="cross" (click)="form.reset()"></sz-icon>
    }
    <span szToHint class="col sz-accent-error">
      ‎
      @if (form.controls.formField.touched) {
        @if (showMultipleErrors) {
          @for (error of this.form.controls.formField.errors | keyvalue: originalOrder; track error) {
            <div>
              {{ stringifyError(error) }}
            </div>
          }
        }
        @if (!showMultipleErrors && (this.form.controls.formField.errors | keyvalue : originalOrder)?.[0]; as error) {
          {{ stringifyError(error) }}
        }
      }
    </span>
  </sz-input>

  <button
    szButton
    type="submit"
    [szLoading]="loading || form.status === 'PENDING'"
    [disabled]="
        loading || disabled || form.invalid || form.status === 'PENDING'
      "
  >
    {{
      form.status === 'PENDING'
        ? 'Валидация'
        : loading
          ? 'Загрузка'
          : buttonText
    }}
  </button>
</form>
