import { createAction, props } from '@ngrx/store';
import { ORDER_STATUSES } from '../order-statuses.constants';
import { OrderStatusesState } from './order-statuses.reducer';

const typeName = (name: string) => `[${ORDER_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getOrderStatusesTrigger = createAction(typeName('getOrderStatuses: Trigger'));
const getOrderStatusesSuccess = createAction(
  typeName('getOrderStatuses: Success'),
  props<{ data: OrderStatusesState['data'] }>()
);
const getOrderStatusesFailure = createAction(
  typeName('getOrderStatuses: Failure'),
  failureProps
);
const getOrderStatusesIsFresh = createAction(typeName('getOrderStatuses: Is Fresh'));
const getOrderStatusesCheckFreshness = createAction(
  typeName('getOrderStatuses: Check Freshness')
);

export const orderStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getOrderStatusesTrigger,
    success: getOrderStatusesSuccess,
    failure: getOrderStatusesFailure,
    isFresh: getOrderStatusesIsFresh,
    checkFreshness: getOrderStatusesCheckFreshness,
  },
};
