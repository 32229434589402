import { createReducer, on } from '@ngrx/store';
import { DefectEliminationDto } from '@monorepo/dictionaries/dto';
import { defectEliminationsActions } from './defect-eliminations.actions';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';

export type DefectEliminationsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: DefectEliminationDto[];
};

export const initialDefectEliminationsState: DefectEliminationsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.defectEliminations,
  data: [],
};

export const defectEliminationsReducer = createReducer(
  initialDefectEliminationsState,

  // state
  on(defectEliminationsActions.state.clear, () => ({
    ...initialDefectEliminationsState,
  })),

  // getData
  on(defectEliminationsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(defectEliminationsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(defectEliminationsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
