import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  map,
  pipe,
  Observable,
  catchError,
  throwError,
  UnaryFunction,
} from 'rxjs';

export type ArrayBufferWithType = {
  contentType: string;
  arrayBuffer: ArrayBuffer;
};

/**
 * map @type {HttpResponse<ArrayBuffer>} to an @type { contentType: string; arrayBuffer: ArrayBuffer }
 */
const mapAbResponse = (res: HttpResponse<ArrayBuffer>): ArrayBufferWithType => {
  const contentType = res.headers.get('Content-Type') as string;
  const arrayBuffer = res.body as ArrayBuffer;
  return { contentType, arrayBuffer };
};

/**
 * catch `ArrayBuffered` @type {HttpErrorResponse} to parse `ArrayBuffer`
 */
const catchAbError = (_error: HttpErrorResponse) => {
  if (!(_error.error instanceof ArrayBuffer)) return throwError(() => _error);
  const error = JSON.parse(new TextDecoder('utf-8').decode(_error.error));
  const url = _error.url || undefined;
  return throwError(() => new HttpErrorResponse({ ..._error, error, url }));
};

/**
 * http options: `{ responseType: 'arraybuffer', observe: 'response' }`
 */
export const parseArrayBufferResponse: UnaryFunction<
  Observable<HttpResponse<ArrayBuffer>>,
  Observable<ArrayBufferWithType>
> = pipe(map(mapAbResponse), catchError(catchAbError));
