import { Injectable } from '@angular/core';
import { OrdersHttpService } from '../orders-http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderStatusesService {
  constructor(private service: OrdersHttpService) {}

  public getData() {
    return this.service.getOrderStatuses();
  }
}
