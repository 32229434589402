import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BusinessPartnersEffects } from './store/business-partners.effects';
import { BUSINESS_PARTNERS } from './business-partners.constants';
import { businessPartnersReducer } from './store/business-partners.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      BUSINESS_PARTNERS.FEATURE_NAME,
      businessPartnersReducer
    ),
    EffectsModule.forFeature([BusinessPartnersEffects]),
    RouterModule.forChild([]),
  ],
})
export class BusinessPartnersModule {}
