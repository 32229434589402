import { Injectable } from '@angular/core';
import { UsersHttpService } from '../users-http.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private usersHttpService: UsersHttpService) {}

  getData() {
    return this.usersHttpService.getAll();
  }
}
