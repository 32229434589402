import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BUSINESS_PARTNERS } from '../business-partners.constants';
import { BusinessPartnersState } from './business-partners.reducer';

const state = createFeatureSelector<BusinessPartnersState>(
  BUSINESS_PARTNERS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const businessPartnersSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
