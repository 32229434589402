import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SSCC_TYPES } from '../sscc-types.constants';
import { SsccTypesState } from './sscc-types.reducer';

const state = createFeatureSelector<SsccTypesState>(SSCC_TYPES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const ssccTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
