import { createAction, props } from '@ngrx/store';
import { CONDITIONS } from '../conditions.constants';
import { ConditionsState } from './conditions.reducer';

const typeName = (name: string) => `[${CONDITIONS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getConditionsTrigger = createAction(typeName('getConditions: Trigger'));
const getConditionsSuccess = createAction(
  typeName('getConditions: Success'),
  props<{ data: ConditionsState['data'] }>()
);
const getConditionsFailure = createAction(
  typeName('getConditions: Failure'),
  failureProps
);
const getConditionsIsFresh = createAction(typeName('getConditions: Is Fresh'));
const getConditionsCheckFreshness = createAction(
  typeName('getConditions: Check Freshness')
);

export const conditionsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getConditionsTrigger,
    success: getConditionsSuccess,
    failure: getConditionsFailure,
    isFresh: getConditionsIsFresh,
    checkFreshness: getConditionsCheckFreshness,
  },
};
