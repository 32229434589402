import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InventoryCountingTypesEffects } from './store/inventory-counting-types.effects';
import { INVENTORY_COUNTING_TYPES } from './inventory-counting-types.constants';
import { inventoryCountingTypesReducer } from './store/inventory-counting-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      INVENTORY_COUNTING_TYPES.FEATURE_NAME,
      inventoryCountingTypesReducer
    ),
    EffectsModule.forFeature([InventoryCountingTypesEffects]),
  ],
})
export class InventoryCountingTypesModule {}
