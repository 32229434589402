import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PRODUCT_ZONES } from './product-zones.constants';
import { ProductZonesEffects } from './store/product-zones.effects';
import { productZonesReducer } from './store/product-zones.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PRODUCT_ZONES.FEATURE_NAME, productZonesReducer),
    EffectsModule.forFeature([ProductZonesEffects]),
    RouterModule.forChild([]),
  ],
})
export class ProductZonesModule {}
