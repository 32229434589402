import { InjectionToken } from '@angular/core';
import { StoreConfig } from '@ngrx/store';
import { AuthState } from './store/auth.reducers';
export const AUTH_STORAGE_KEY = new InjectionToken<string>('AUTH_STORAGE_KEY');
export const AUTH_SAVE_KEYS = new InjectionToken<string[]>('AUTH_SAVE_KEYS');
export const AUTH_META_REDUCERS = new InjectionToken<StoreConfig<AuthState>>(
  'AUTH_META_REDUCERS'
);

import _jwtDecode from 'jwt-decode';
import { JwtExtended } from '@monorepo/auth/shared';
type JwtDecodeTyped = (
  ...params: Parameters<typeof _jwtDecode>
) => JwtExtended & { exp: number };
export const JWT_DECODE = new InjectionToken<JwtDecodeTyped>('jwt-decode', {
  factory: () => {
    return _jwtDecode;
  },
});
