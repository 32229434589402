import { createAction, props } from '@ngrx/store';
import { ZONES_ALL } from '../zones-all.constants';
import { ZonesAllState } from './zones-all.reducer';

const typeName = (name: string) => `[${ZONES_ALL.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getZonesAllTrigger = createAction(typeName('getZonesAll: Trigger'));
const getZonesAllSuccess = createAction(
  typeName('getZonesAll: Success'),
  props<{ data: ZonesAllState['data'] }>()
);
const getZonesAllFailure = createAction(
  typeName('getZonesAll: Failure'),
  failureProps
);
const getZonesAllIsFresh = createAction(typeName('getZonesAll: Is Fresh'));
const getZonesAllCheckFreshness = createAction(
  typeName('getZonesAll: Check Freshness')
);

export const zonesAllActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getZonesAllTrigger,
    success: getZonesAllSuccess,
    failure: getZonesAllFailure,
    isFresh: getZonesAllIsFresh,
    checkFreshness: getZonesAllCheckFreshness,
  },
};
