import { createAction, props } from '@ngrx/store';
import { PLACE_TYPES } from '../place-types.constants';
import { PlaceTypesState } from './place-types.reducer';

const typeName = (name: string) => `[${PLACE_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getPlaceTypesTrigger = createAction(typeName('getPlaceTypes: Trigger'));
const getPlaceTypesSuccess = createAction(
  typeName('getPlaceTypes: Success'),
  props<{ data: PlaceTypesState['data'] }>()
);
const getPlaceTypesFailure = createAction(
  typeName('getPlaceTypes: Failure'),
  failureProps
);
const getPlaceTypesIsFresh = createAction(typeName('getPlaceTypes: Is Fresh'));
const getPlaceTypesCheckFreshness = createAction(
  typeName('getPlaceTypes: Check Freshness')
);

export const placeTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getPlaceTypesTrigger,
    success: getPlaceTypesSuccess,
    failure: getPlaceTypesFailure,
    isFresh: getPlaceTypesIsFresh,
    checkFreshness: getPlaceTypesCheckFreshness,
  },
};
