import { Injectable } from '@angular/core';
import { InventoryHttpService } from '../inventory-http.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryTypesService {
  constructor(private inventoryHttpService: InventoryHttpService) {}

  getData() {
    return this.inventoryHttpService.getTypes();
  }
}
