import {NgModule} from "@angular/core";
import {PrintersService} from "./printers.service";
import {provideStates} from "@ngxs/store";
import {PrintersDictionaryState} from "./state/printers.state";
import {PrintersDictionaryFacade} from "./state/printers.facade";

@NgModule({
  providers: [
    PrintersService,
    PrintersDictionaryFacade,
    provideStates([PrintersDictionaryState])
  ],
})
export class PrintersDictionaryModule {}
