import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEPARTMENTS } from '../departments.constants';
import { DepartmentsState } from './departments.reducer';

const state = createFeatureSelector<DepartmentsState>(DEPARTMENTS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const departmentsSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
