import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TcState
} from "./transport-companies.reducer";
import {TRANSPORT_COMPANIES} from "../tc-all.constants";

const state = createFeatureSelector<TcState>(TRANSPORT_COMPANIES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const tcSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
