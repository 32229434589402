import {createReducer, on} from "@ngrx/store";
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import {listGroupsActions} from "./list-groups.actions";
import {ListGroupDto} from "@monorepo/dictionaries/dto";

export type ListGroupsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: ListGroupDto[];
};
export const initialListGroupsState: ListGroupsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.mistakes,
  data: [],
};

export const listGroupsReducer = createReducer(
  initialListGroupsState,

  on(listGroupsActions.state.clear, () => ({ ...initialListGroupsState })),

  on(listGroupsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  on(listGroupsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(listGroupsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
