import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';
import { FormAllErrorsComponent } from '../form-errors';

type Entity = { id: number; name: string };
interface Queried<T> {
  data$: Observable<T>;
  loading$: Observable<boolean>;
  updatedAt$: Observable<number>;
  refresh: () => void;
}

type FormValue = {
  id: number;
};

type TypedFormGroup = FormGroup<{
  [K in keyof FormValue]: FormControl<FormValue[K] | null>;
}>;

@Component({
  selector: 'forms-choose-entity',
  styles: [
    `
      .mat-mdc-form-field {
        display: block;
        width: 100%;
      }

      forms-all-errors {
        margin-top: 1rem;
      }

      .actions {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
  template: `
    <form [formGroup]="form" (ngSubmit)="save()">
      <ng-template #selectOptionLoading>
        <span
          style="display: flex; align-items: center; justify-content: space-between"
        >
          Загрузка
          <mat-spinner diameter="20" style="display: inline-block">
          </mat-spinner>
        </span>
      </ng-template>

      <mat-form-field>
        <mat-select [placeholder]="FIELD_LABELS.id" formControlName="id">
          <mat-option *ngIf="(entityDictionary.data$ | async)?.length">
            <ngx-mat-select-search
              [formControl]="entitySearchControl"
            ></ngx-mat-select-search>
          </mat-option>

          <mat-option *ngIf="entityDictionary.loading$ | async as isLoading">
            <ng-container
              *ngTemplateOutlet="selectOptionLoading"
            ></ng-container>
          </mat-option>

          <ng-container *ngIf="entityDictionary.data$ | async as options">
            <mat-option
              *ngFor="let item of filteredEntities$ | async"
              [value]="item.id"
            >
              {{ item.name }}
            </mat-option>

            <mat-option
              *ngIf="
                (entityDictionary.loading$ | async) === false &&
                (entityDictionary.updatedAt$ | async) &&
                !options?.length
              "
            >
              Пусто
            </mat-option>

            <mat-option
              *ngIf="
                (entityDictionary.loading$ | async) === false &&
                (entityDictionary.updatedAt$ | async) === 0 &&
                !options?.length
              "
            >
              Не удалось получить список.
              <button
                type="button"
                mat-stroked-button
                (click)="entityDictionary.refresh()"
              >
                Попробовать ещё раз
              </button>
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <forms-all-errors
        [form]="form"
        [fields]="FIELD_LABELS"
      ></forms-all-errors>

      <div class="actions">
        <button
          *ngIf="!hideBackButton"
          type="button"
          mat-raised-button
          (click)="close()"
          [disabled]="loading"
        >
          {{ backButton }}
        </button>
        <div *ngIf="hideBackButton"></div>

        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="form.invalid || loading"
        >
          <mat-spinner
            *ngIf="loading"
            diameter="20"
            style="display: inline-block"
          ></mat-spinner>
          <span>{{ submitButton }}</span>
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormAllErrorsComponent,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    NgxMatSelectSearchModule,
  ],
})
export class ChooseEntityComponent implements OnDestroy, OnInit {
  destroyed$ = new Subject<void>();

  form: TypedFormGroup = this.fb.group({
    id: this.fb.control<number | null>(null, Validators.required),
  });
  entitySearchControl = this.fb.control<string>('');
  @Output()
  entitySearchControlValue$ = this.entitySearchControl.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  /** might be passed as input, or will be init inside `onInit` reacting on `entitySearchControlValue$` */
  @Input() filteredEntities$?: BehaviorSubject<Entity[]>;
  @Input() backButton?: string;
  @Input() submitButton?: string;
  @Input() hideBackButton?: boolean;
  @Input() FIELD_LABELS: Record<keyof FormValue, string> = { id: 'ID' };
  @Input() loading: boolean | null = false;
  @Input() initialId: number | undefined;
  @Input() entityDictionary!: Queried<Entity[]>;

  @Output() done = new EventEmitter<number | undefined>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.initialId) this.form.patchValue({ id: this.initialId });
    if (!this.backButton) this.backButton = 'Назад';
    if (!this.submitButton) this.submitButton = 'Выбрать';
    if (!this.filteredEntities$) {
      this.filteredEntities$ = new BehaviorSubject<Entity[]>([]);

      combineLatest([
        this.entityDictionary.data$,
        this.entitySearchControlValue$,
      ])
        .pipe(
          takeUntil(this.destroyed$),
          map(([arr, search]) =>
            !search
              ? arr
              : arr.filter((item) =>
                  item.name.toLowerCase().includes(search.toLowerCase())
                )
          )
        )
        .subscribe(this.filteredEntities$);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    const value = this.form.getRawValue();
    if (this.form.invalid || !value.id) return;
    this.done.emit(value.id);
  }

  close() {
    this.done.emit(undefined);
  }
}
