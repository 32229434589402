import {MovementDocumentStatusesHttpService} from "../movement-document-statuses-http.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class StatusesAllService {
  constructor(private statusesHttpService: MovementDocumentStatusesHttpService) {
  }

  getData() {
    return this.statusesHttpService.getAll();
  }
}
