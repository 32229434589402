import { createReducer, on } from '@ngrx/store';
import { authActions } from './auth.actions';

export interface User {
  id: number;
  login: string;
  fullName: string;
  isActive: boolean;
  department: { id: number; name: string };
  workPlace: { id: number; name: string };
}

export interface AuthState {
  user: User | null;
  token: string | null;
  tokenExpires: Date | null;
  loading: boolean;
}

export const initialAuthState: AuthState = {
  user: null,
  token: null,
  tokenExpires: null,
  loading: false,
};

export const authReducer = createReducer(
  initialAuthState,

  // state
  on(authActions.state.clear, () => {
    return { ...initialAuthState };
  }),
  on(authActions.setPlace, (state, { place }) => {
    if (!state.user) return state;
    return {
      ...state,
      user: {
        ...state.user,
        workPlace: { ...state.user?.workPlace, ...place },
      },
    };
  }),

  // login
  on(authActions.login.success, (state, { type, ...payload }) => {
    return { ...state, ...payload, loading: false };
  }),

  // loadings
  on(authActions.login.trigger, (state) => ({ ...state, loading: true })),

  on(authActions.login.failure, (state) => ({ ...state, loading: false }))
);
