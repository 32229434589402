import {SupervisorsHttpService} from "../supervisors-http.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class SupervisorsAllService {
  constructor(private supervisorsHttpService: SupervisorsHttpService) {
  }

  getData() {
    return this.supervisorsHttpService.getAll();
  }
}
