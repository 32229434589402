import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@monorepo/ui/core';
import { AuthResponseDto, AuthDto } from '@monorepo/auth/dto';
import { Observable } from 'rxjs';
import { makeUrl, restQuery } from '@monorepo/ui/helpers';
import { AUTH_SAC } from '@monorepo/auth/shared';
import { PlaceDto } from '@monorepo/acceptance/dto';

const { CONTROLLER, LOGIN } = AUTH_SAC;

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getPlace = restQuery((place: string) => {
    const URL = `${this.apiUrl}/api/v3/topology/place/name/${place}`; // todo: to sac
    return this.http.get<PlaceDto>(URL);
  });

  login(body: AuthDto): Observable<AuthResponseDto> {
    const { url } = LOGIN();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.post<AuthResponseDto>(URL, body);
  }
}
