import { createAction, props } from '@ngrx/store';
import {
  MovementDocStatusesState
} from "./movement-statuses.reducer";
import {
  MOVEMENT_DOC_STATUSES
} from "../statuses-all.constants";

const typeName = (name: string) => `[${MOVEMENT_DOC_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getMovementDocStatusesTrigger = createAction(typeName('getMovementDocStatuses: Trigger'));
const getMovementDocStatusesSuccess = createAction(
  typeName('getMovementDocStatuses: Success'),
  props<{ data: MovementDocStatusesState['data'] }>()
);
const getMovementDocStatusesFailure = createAction(
  typeName('getMovementDocStatuses: Failure'),
  failureProps
);
const getMovementDocStatusesIsFresh = createAction(typeName('getMovementDocStatuses: Is Fresh'));
const getMovementDocStatusesCheckFreshness = createAction(
  typeName('getMovementDocStatuses: Check Freshness')
);

export const movementDocStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getMovementDocStatusesTrigger,
    success: getMovementDocStatusesSuccess,
    failure: getMovementDocStatusesFailure,
    isFresh: getMovementDocStatusesIsFresh,
    checkFreshness: getMovementDocStatusesCheckFreshness,
  },
};
