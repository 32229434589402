import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INVENTORY_STATUSES } from '../inventory-statuses.constants';
import { InventoryStatusesState } from './inventory-statuses.reducer';

const state = createFeatureSelector<InventoryStatusesState>(
  INVENTORY_STATUSES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const inventoryStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
