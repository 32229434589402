import { createAction, props } from '@ngrx/store';
import { INVENTORY_TYPES } from '../inventory-types.constants';
import { InventoryTypesState } from './inventory-types.reducer';

const typeName = (name: string) => `[${INVENTORY_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getInventoryTypesTrigger = createAction(
  typeName('getInventoryTypes: Trigger')
);
const getInventoryTypesSuccess = createAction(
  typeName('getInventoryTypes: Success'),
  props<{ data: InventoryTypesState['data'] }>()
);
const getInventoryTypesFailure = createAction(
  typeName('getInventoryTypes: Failure'),
  failureProps
);
const getInventoryTypesIsFresh = createAction(
  typeName('getInventoryTypes: Is Fresh')
);
const getInventoryTypesCheckFreshness = createAction(
  typeName('getInventoryTypes: Check Freshness')
);

export const inventoryTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getInventoryTypesTrigger,
    success: getInventoryTypesSuccess,
    failure: getInventoryTypesFailure,
    isFresh: getInventoryTypesIsFresh,
    checkFreshness: getInventoryTypesCheckFreshness,
  },
};
