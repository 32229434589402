import { createReducer, on } from '@ngrx/store';
import type { UserDto } from '@monorepo/dictionaries/dto';
export type { UserDto };
import { usersActions } from './users.actions';
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';

export type UsersState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: UserDto[];
};

export const initialUsersState: UsersState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.users,
  data: [],
};

export const usersReducer = createReducer(
  initialUsersState,

  // state
  on(usersActions.state.clear, () => ({ ...initialUsersState })),

  // getUsers
  on(usersActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(usersActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(usersActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
