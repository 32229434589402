import {NgModule} from "@angular/core";
import {PrintFormsService} from "./print-forms.service";
import {PrintFormsDictionaryState} from "./state/print-forms.state";
import {provideStates} from "@ngxs/store";
import {PrintFormsDictionaryFacade} from "./state/print-forms.facade";

@NgModule({
  providers: [
    PrintFormsService,
    provideStates([PrintFormsDictionaryState]),
    PrintFormsDictionaryFacade,
  ]
})
export class PrintFormsDictionaryModule {}
