import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_URL} from "@monorepo/ui/core";
import {makeUrl} from "@monorepo/ui/helpers";
import {DICTIONARIES_PRINTERS_API} from "@monorepo/dictionaries/shared";
import {Observable} from "rxjs";
import {PrinterSuccessResponseDto} from "@monorepo/dictionaries/dto";
const { CONTROLLER, ALL } = DICTIONARIES_PRINTERS_API;

@Injectable()
export class PrintersService {
  private http = inject(HttpClient);
  private apiUrl = inject(API_URL);
  private BASE_URL = makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  public load(): Observable<PrinterSuccessResponseDto> {
    const url = makeUrl(this.BASE_URL, ALL().url);
    return this.http.get<PrinterSuccessResponseDto>(url);
  }
}
