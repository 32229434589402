import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RegistryTypesState
} from "./registry-types.reducer";
import {REGISTRY_TYPES} from "../registry-types-all.constants";

const state = createFeatureSelector<RegistryTypesState>(REGISTRY_TYPES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const registryTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
