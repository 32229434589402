import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OFF_SYSTEM_STEPS } from '../off-system-steps.constants';
import { OffSystemStepsState } from './off-system-steps.reducer';

const state = createFeatureSelector<OffSystemStepsState>(
  OFF_SYSTEM_STEPS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const offSystemStepsSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
