import { createAction, props } from '@ngrx/store';
import { SSCC_TYPES } from '../sscc-types.constants';
import { SsccTypesState } from './sscc-types.reducer';

const typeName = (name: string) => `[${SSCC_TYPES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getSsccTypesTrigger = createAction(typeName('getSsccTypes: Trigger'));
const getSsccTypesSuccess = createAction(
  typeName('getSsccTypes: Success'),
  props<{ data: SsccTypesState['data'] }>()
);
const getSsccTypesFailure = createAction(
  typeName('getSsccTypes: Failure'),
  failureProps
);
const getSsccTypesIsFresh = createAction(typeName('getSsccTypes: Is Fresh'));
const getSsccTypesCheckFreshness = createAction(
  typeName('getSsccTypes: Check Freshness')
);

export const ssccTypesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getSsccTypesTrigger,
    success: getSsccTypesSuccess,
    failure: getSsccTypesFailure,
    isFresh: getSsccTypesIsFresh,
    checkFreshness: getSsccTypesCheckFreshness,
  },
};
