import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '@monorepo/ui/core';
import { ZoneDto } from '@monorepo/dictionaries/dto';
import { makeUrl } from '@monorepo/ui/helpers';
import { DICTIONARIES_TOPOLOGY_API } from '@monorepo/dictionaries/shared';

const { CONTROLLER, ZONES } = DICTIONARIES_TOPOLOGY_API;

@Injectable({
  providedIn: 'root',
})
export class ZonesAllService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getData() {
    const { url } = ZONES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<ZoneDto[]>(URL);
  }
}
