import { createAction, props } from '@ngrx/store';
import { ACCEPTANCE_STATUSES } from '../acceptance-statuses.constants';
import { AcceptanceStatusesState } from './acceptance-statuses.reducer';

const typeName = (name: string) =>
  `[${ACCEPTANCE_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getAcceptanceStatusesTrigger = createAction(
  typeName('getAcceptanceStatuses: Trigger')
);
const getAcceptanceStatusesSuccess = createAction(
  typeName('getAcceptanceStatuses: Success'),
  props<{ data: AcceptanceStatusesState['data'] }>()
);
const getAcceptanceStatusesFailure = createAction(
  typeName('getAcceptanceStatuses: Failure'),
  failureProps
);
const getAcceptanceStatusesIsFresh = createAction(
  typeName('getAcceptanceStatuses: Is Fresh')
);
const getAcceptanceStatusesCheckFreshness = createAction(
  typeName('getAcceptanceStatuses: Check Freshness')
);

export const acceptanceStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getAcceptanceStatusesTrigger,
    success: getAcceptanceStatusesSuccess,
    failure: getAcceptanceStatusesFailure,
    isFresh: getAcceptanceStatusesIsFresh,
    checkFreshness: getAcceptanceStatusesCheckFreshness,
  },
};
