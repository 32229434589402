import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';
import { from, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import {
  isMarkingOrKizCodeValid,
  markingCodeScanFailed,
  outdatedMarkingCode,
} from './marking-code';

type CustomScanFieldError = {
  message: string;
  isErrorFn: (value: string | undefined) => boolean | string;
};

type AsyncCustomScanFieldError = {
  message: string;
  isErrorAsyncFn: (value: string) => Observable<unknown> | Promise<unknown>;
};

const createValidatorFn =
  ({ isErrorFn, message }: CustomScanFieldError) =>
  (field: AbstractControl): { customError: string } | null => {
    const error = isErrorFn(field?.value?.toString() ?? undefined);
    const customError = typeof error === 'string' ? error : message;

    return error ? { customError } : null;
  };

const createAsyncValidatorFn =
  ({ isErrorAsyncFn, message }: AsyncCustomScanFieldError) =>
  (field: AbstractControl): Observable<{ asyncCustomError: string } | null> => {
    return timer(500).pipe(
      switchMap(() => from(isErrorAsyncFn(field.value))),
      take(1),
      map(() => null),
      catchError((error) => {
        const asyncCustomError = typeof error === 'string' ? error : message;
        return of({ asyncCustomError });
      })
    );
  };

const length =
  (length: number, message?: string) =>
  (field: AbstractControl): { length: string } | null => {
    return field?.value?.length === length
      ? null
      : { length: message || `Требуемая длина - ${length}` };
  };

const minLength =
  (length: number, message?: string) =>
  (field: AbstractControl): { length: string } | null => {
    return field?.value?.length >= length
      ? null
      : { length: message || `Минимальная длина - ${length}` };
  };

const maxLength =
  (length: number, message?: string) =>
    (field: AbstractControl): { length: string } | null => {
      return field?.value?.length >= length
        ? { length: message || `Максимальная длина - ${length}` }
        : null;
    };

const startsWith =
  (startsString: string, message?: string) =>
  (field: AbstractControl): { startsWith: string } | null => {
    return field?.value?.startsWith(startsString)
      ? null
      : { startsWith: message || `Поле должно начинаться с ${startsString}` };
  };

const markingCode = createValidatorFn({
  isErrorFn: (markingCode = '') => {
    const mk31 = markingCode.slice(0, 31);
    if (outdatedMarkingCode(mk31)) {
      return 'КМ устарел';
    }
    if (markingCodeScanFailed(mk31)) {
      return 'КМ не распознан. Отсканируйте повторно';
    }
    return !isMarkingOrKizCodeValid(mk31);
  },
  message: 'Неверный формат кода маркировки',
});

const allowedDefectCode =
  (values: number[]) =>
    (control: AbstractControl): ValidationErrors | null => {
      const check = values.includes(Number(control.value));
      return check ? null : {message: 'Введен некорректный дефект'};
    };

const allowedZoneCode =
  (values: string[]) =>
    (control: AbstractControl): ValidationErrors | null => {
      const check = values.includes(control.value?.toString());
      return check ? null : {message: 'Введен некорректный сектор'};
    };

const pattern =
  (pattern: RegExp | string, message?: string) =>
  (field: AbstractControl): { pattern: string } | null => {
    return Validators.pattern(pattern)(field)?.['pattern']
      ? { pattern: message || `Поле должно быть вида ${pattern}` }
      : null;
  };

/**
 * Number.isInteger()
 */
const integer = createValidatorFn({
  isErrorFn: (num = '') => !Number.isInteger(+num),
  message: 'Число должно быть целым',
});

/**
 * Only numbers (012345, 12345, 00001)
 */
const numeric = createValidatorFn({
  isErrorFn: (num = '') => !/^\d+$/.test(num),
  message: 'Разрешены только цифры',
});

/**
 * Float or int number (1, 1.1, 0.001)
 */
const number = createValidatorFn({
  isErrorFn: (num = '') => !(num === (+num).toString()),
  message: 'Введите корректное число',
});

const boxOrPallet = createValidatorFn({
  isErrorFn: (sscc = '') =>
    !sscc.startsWith('0466012953') && !sscc.startsWith('1466012953'),
  message: 'Неверный формат SSCC короба или паллета',
});

const allowedPlaces =
  (allowedPlaces: string[]) =>
    (field: AbstractControl): { message: string } | null => {
      return allowedPlaces.some(substring => field.value.toLowerCase().includes(substring.toLowerCase()))
        ? null
        : { message: 'Отсканируйте подходящее рабочее место' };
    };

export const CUSTOM_VALIDATORS = {
  length,
  minLength,
  maxLength,
  startsWith,
  markingCode,
  createValidatorFn,
  pattern,
  number,
  integer,
  numeric,
  boxOrPallet,
  allowedDefectCode,
  allowedZoneCode,
  allowedPlaces,
};

export const CUSTOM_ASYNC_VALIDATORS = {
  createAsyncValidatorFn,
};

export const CUSTOM_VALIDATORS_PRESETS = {
  uiid: [CUSTOM_VALIDATORS.numeric, Validators.min(500000)],
  sku: [CUSTOM_VALIDATORS.numeric, CUSTOM_VALIDATORS.length(13)],
  mk: [CUSTOM_VALIDATORS.markingCode],
  ssccBox: [
    CUSTOM_VALIDATORS.startsWith('0466012953', 'Некорректный SSCC короба'),
    CUSTOM_VALIDATORS.length(18, 'Длина SSCC 18 символов'),
  ],
  ssccPallet: [
    CUSTOM_VALIDATORS.startsWith('1466012953', 'Некорректный SSCC паллета'),
    CUSTOM_VALIDATORS.length(18, 'Длина SSCC 18 символов'),
  ],
  ssccBoxOrPallet: [
    CUSTOM_VALIDATORS.boxOrPallet,
    CUSTOM_VALIDATORS.length(18, 'Длина SSCC 18 символов'),
  ],
};
