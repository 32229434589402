import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  ReactiveFormsModule,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormAllErrorsComponent } from '../form-errors';
import { Observable } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';

type FormValue = {
  name: string;
  fullname: string;
  isActive: boolean;
  departmentId: number;
};

export type UserCreateEditFormValue = FormValue;

type Entity = { id: number; name: string };
interface Queried<T> {
  data$: Observable<T>;
  loading$: Observable<boolean>;
  updatedAt$: Observable<number>;
  refresh: () => void;
}

type TypedFormGroup = FormGroup<{
  [K in keyof FormValue]: FormControl<FormValue[K] | null>;
}>;

@Component({
  selector: 'forms-user-create-edit',
  styleUrls: ['./user-create-edit.component.scss'],
  templateUrl: './user-create-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    FormAllErrorsComponent,
  ],
})
export class UserCreateEditComponent implements OnInit {
  form: TypedFormGroup = this.fb.group({
    name: this.fb.control<string | null>(null, [Validators.required]),
    fullname: this.fb.control<string | null>(null, [Validators.required]),
    isActive: this.fb.control<boolean>(false, [Validators.required]),
    departmentId: this.fb.control<number | null>(null, [Validators.required]),
  });
  FIELD_LABELS: Record<keyof FormValue, string> = {
    name: 'Логин',
    fullname: 'ФИО',
    isActive: 'Активен',
    departmentId: 'Департамент',
  };
  submitButton = '';

  @Input() loading: boolean | null = false;
  @Input() initialFormValue?: Partial<FormValue>;
  @Input() departmentsDictionary!: Queried<Entity[]>;
  @Output() done = new EventEmitter<FormValue>();
  @Output() closed = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (this.initialFormValue) {
      this.submitButton = 'Сохранить';
      this.form.patchValue(this.initialFormValue);
      this.form.controls.name.disable();
    } else {
      this.submitButton = 'Создать';
    }
  }

  save() {
    const value = this.form.getRawValue();
    if (this.form.invalid) return;
    this.done.emit(value as FormValue);
  }

  close() {
    this.closed.emit();
  }
}
