import { Injectable } from '@angular/core';
import { DictionariesHttpService } from '../dictionaries-http.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  constructor(private DictionariesHttpService: DictionariesHttpService) {}

  getData() {
    return this.DictionariesHttpService.getDepartments();
  }
}
