import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { usersMastersActions } from './users-masters.actions';
import { usersMastersSelectors } from './users-masters.selectors';
export type { UserDto } from './users-masters.reducer';

// also facades can be used instead of effects
// https://gist.github.com/ThomasBurleson/38d067abad03b56f1c9caf28ff0f4ebd
@Injectable({
  providedIn: 'root',
})
export class UsersMastersFacade {
  loading$ = this.store.select(usersMastersSelectors.loading);
  updatedAt$ = this.store.select(usersMastersSelectors.updatedAt);
  data$ = this.store.select(usersMastersSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(usersMastersActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(usersMastersActions.getData.trigger());
  }
}
