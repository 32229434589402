import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject } from 'rxjs';
import { CUSTOM_VALIDATORS } from '../../validators/custom-validators';
import { FormAllErrorsComponent } from '../form-errors';

type FormValue = { priority: number };

type TypedFormGroup = FormGroup<{
  [K in keyof FormValue]: FormControl<FormValue[K] | null>;
}>;

@Component({
  selector: 'forms-set-priority',
  styles: [
    `
      .mat-mdc-form-field {
        display: block;
        width: 100%;
      }

      forms-all-errors {
        margin-top: 1rem;
      }

      .actions {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
      }
    `,
  ],
  template: `
    <form [formGroup]="form" (ngSubmit)="save()">
      <mat-form-field>
        <mat-label>{{ FIELD_LABELS.priority }}</mat-label>
        <input
          matInput
          type="number"
          autocomplete="off"
          formControlName="priority"
        />
      </mat-form-field>

      <forms-all-errors
        [form]="form"
        [fields]="FIELD_LABELS"
      ></forms-all-errors>

      <div class="actions">
        <button
          type="button"
          mat-raised-button
          (click)="close()"
          [disabled]="loading"
        >
          Назад
        </button>

        <button
          type="submit"
          color="primary"
          mat-raised-button
          [disabled]="form.invalid || loading"
        >
          <mat-spinner
            *ngIf="loading"
            diameter="20"
            style="display: inline-block"
          ></mat-spinner>
          <span>{{ submitButton }}</span>
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    FormAllErrorsComponent,
  ],
})
export class SetPriorityComponent implements OnDestroy, OnInit {
  form: TypedFormGroup = this.fb.group({
    priority: this.fb.control<number | null>(null, [
      CUSTOM_VALIDATORS.integer,
      Validators.required,
      Validators.min(1),
      Validators.max(100),
    ]),
  });
  submitButton = '';

  FIELD_LABELS: Record<keyof FormValue, string> = {
    priority: 'Приоритет',
  };

  @Input() loading: boolean | null = false;
  @Input() initialPriority: number | undefined;
  @Output() done = new EventEmitter<number>();

  destroyed$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.submitButton = this.initialPriority ? 'Переназначить' : 'Назначить';
    if (this.initialPriority) {
      this.form.patchValue({ priority: this.initialPriority });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    const value = this.form.getRawValue();
    if (this.form.invalid || !value.priority) return;
    this.done.emit(value.priority);
  }

  close() {
    this.done.emit(undefined);
  }
}
