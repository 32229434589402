import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCEPTANCE_STATUSES } from '../acceptance-statuses.constants';
import { AcceptanceStatusesState } from './acceptance-statuses.reducer';

const state = createFeatureSelector<AcceptanceStatusesState>(
  ACCEPTANCE_STATUSES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const acceptanceStatusesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
