import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DefectCommentsEffects } from './store/defect-comments.effects';
import { DEFECT_COMMENTS } from './defect-comments.constants';
import { defectCommentsReducer } from './store/defect-comments.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DEFECT_COMMENTS.FEATURE_NAME, defectCommentsReducer),
    EffectsModule.forFeature([DefectCommentsEffects]),
    RouterModule.forChild([]),
  ],
})
export class DefectCommentsModule {}
