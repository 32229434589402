import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  InventoryCountingTypeDto,
  InventoryListStatusDto,
  InventoryStatusDto,
  InventoryTypeDto,
} from '@monorepo/dictionaries/dto';
import { DICTIONARIES_INVENTORY_API } from '@monorepo/dictionaries/shared';
import { API_URL } from '@monorepo/ui/core';
import { makeUrl } from '@monorepo/ui/helpers';

const { CONTROLLER, COUNTING_TYPES, STATUSES, TYPES, LIST_STATUSES } =
  DICTIONARIES_INVENTORY_API;

@Injectable({
  providedIn: 'root',
})
export class InventoryHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getCountingTypes() {
    const { url } = COUNTING_TYPES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<InventoryCountingTypeDto[]>(URL);
  }

  getStatuses() {
    const { url } = STATUSES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<InventoryStatusDto[]>(URL);
  }

  getTypes() {
    const { url } = TYPES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<InventoryTypeDto[]>(URL);
  }

  getListStatuses() {
    const { url } = LIST_STATUSES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<InventoryListStatusDto[]>(URL);
  }
}
