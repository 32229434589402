import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MovementDocStatusesState
} from "./movement-statuses.reducer";
import {
  MOVEMENT_DOC_STATUSES
} from "../statuses-all.constants";

const state = createFeatureSelector<MovementDocStatusesState>(MOVEMENT_DOC_STATUSES.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const movementDocStatusesSelector = {
  data,
  state,
  loading,
  updatedAt,
};
