import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AcceptanceTypesEffects } from './store/acceptance-types.effects';
import { ACCEPTANCE_TYPES } from './acceptance-types.constants';
import { acceptanceTypesReducer } from './store/acceptance-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      ACCEPTANCE_TYPES.FEATURE_NAME,
      acceptanceTypesReducer
    ),
    EffectsModule.forFeature([AcceptanceTypesEffects]),
    RouterModule.forChild([]),
  ],
})
export class AcceptanceTypesModule {}
