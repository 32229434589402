import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DefectEliminationsEffects } from './store/defect-eliminations.effects';
import { DEFECT_ELIMINATIONS } from './defect-eliminations.constants';
import { defectEliminationsReducer } from './store/defect-eliminations.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      DEFECT_ELIMINATIONS.FEATURE_NAME,
      defectEliminationsReducer
    ),
    EffectsModule.forFeature([DefectEliminationsEffects]),
    RouterModule.forChild([]),
  ],
})
export class DefectEliminationsModule {}
