import {createReducer, on} from "@ngrx/store";
import { DictionariesCacheTime } from '../../../../dictionaries.cache-time';
import {MovementDocumentStatusDto} from "@monorepo/dictionaries/dto";
import {
  movementDocStatusesActions
} from "./movement-statuses.actions";

export type MovementDocStatusesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: MovementDocumentStatusDto[];
};
export const initialMovementDocStatusesState: MovementDocStatusesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.movementDocStatuses,
  data: [],
};

export const movementStatusesReducer = createReducer(
  initialMovementDocStatusesState,

  // state
  on(movementDocStatusesActions.state.clear, () => ({ ...initialMovementDocStatusesState })),

  // getUsers
  on(movementDocStatusesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(movementDocStatusesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(movementDocStatusesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
)
