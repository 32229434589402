import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {MESSAGE_STATUSES} from "./message-statuses.constant";
import {
  messageStatusesReducer
} from "./store/message-statuses.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  MessageStatusesEffects
} from "./store/message-statuses.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MESSAGE_STATUSES.FEATURE_NAME, messageStatusesReducer),
    EffectsModule.forFeature([MessageStatusesEffects])
  ],
})
export class MessageStatusesModule {}
