import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PlaceTypesEffects } from './store/place-types.effects';
import { PLACE_TYPES } from './place-types.constants';
import { placeTypesReducer } from './store/place-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(PLACE_TYPES.FEATURE_NAME, placeTypesReducer),
    EffectsModule.forFeature([PlaceTypesEffects]),
  ],
})
export class PlaceTypesModule {}
