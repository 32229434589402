import { throwError } from 'rxjs';

export function catchV1Error(error: {
  error: {
    error: string;
    message: string;
    status: string;
    success: boolean;
  };
}) {
  return throwError(() => error?.error?.error || error?.error || error);
}
