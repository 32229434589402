import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { API_URL } from '@monorepo/ui/core';

import { DICTIONARIES_API } from '@monorepo/dictionaries/shared';
import { makeUrl } from '@monorepo/ui/helpers';
import {
  DefectComentDto,
  DefectEliminationDto,
  ProductDefectDto,
  ProductZoneDto,
  ConditionDto,
  AcceptanceStatusDto,
  AcceptanceTypeDto,
  BusinessPartnerDto,
  TradeItemGroupDto,
  DepartmentDto,
  OffSystemStepDto,
} from '@monorepo/dictionaries/dto';

const {
  CONTROLLER,
  GET_DEFECT_COMMENTS,
  GET_DEFECT_ELIMINATION,
  GET_PRODUCT_DEFECTS,
  GET_PRODUCT_ZONES,
  GET_CONDITIONS,
  GET_ACCEPTANCE_STATUSES,
  GET_ACCEPTANCE_TYPES,
  GET_BUSINESS_PARTNERS,
  GET_TRADE_ITEM_GROUPS,
  GET_SSCC_TYPES,
  GET_DEPARTMENTS,
  GET_OFF_SYSTEM_STEPS,
} = DICTIONARIES_API;

@Injectable({ providedIn: 'root' })
export class DictionariesHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getProductDefects() {
    const { url } = GET_PRODUCT_DEFECTS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<ProductDefectDto[]>(URL);
  }

  getDepartments() {
    const { url } = GET_DEPARTMENTS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<DepartmentDto[]>(URL);
  }

  getDefectEliminations() {
    const { url } = GET_DEFECT_ELIMINATION();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<DefectEliminationDto[]>(URL);
  }

  getDefectComments() {
    const { url } = GET_DEFECT_COMMENTS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<DefectComentDto[]>(URL);
  }

  getProductZones() {
    const { url } = GET_PRODUCT_ZONES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<ProductZoneDto[]>(URL);
  }

  getConditions() {
    const { url } = GET_CONDITIONS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<ConditionDto[]>(URL);
  }

  getBusinessPartners() {
    const { url } = GET_BUSINESS_PARTNERS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<BusinessPartnerDto[]>(URL);
  }

  getAcceptanceTypes() {
    const { url } = GET_ACCEPTANCE_TYPES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<AcceptanceTypeDto[]>(URL);
  }

  getSsccTypes() {
    const { url } = GET_SSCC_TYPES();
    const URL = makeUrl(this.BASE_URL(), url);
    // todo: real dto
    return this.http.get<any>(URL);
  }

  getAcceptanceStatuses() {
    const { url } = GET_ACCEPTANCE_STATUSES();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<AcceptanceStatusDto[]>(URL);
  }

  getTradeItemGroups() {
    const { url } = GET_TRADE_ITEM_GROUPS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<TradeItemGroupDto[]>(URL);
  }

  getOffSystemSteps() {
    const { url } = GET_OFF_SYSTEM_STEPS();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<OffSystemStepDto[]>(URL);
  }
}
