import { createAction, props } from '@ngrx/store';
import {
  MovementDocObjectsState
} from "./movement-object.reducer";
import {
  MOVEMENT_DOC_OBJECTS
} from "../object-all.constants";

const typeName = (name: string) => `[${MOVEMENT_DOC_OBJECTS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getMovementDocObjectsTrigger = createAction(typeName('getMovementDocObjects: Trigger'));
const getMovementDocObjectsSuccess = createAction(
  typeName('getMovementDocObjects: Success'),
  props<{ data: MovementDocObjectsState['data'] }>()
);
const getMovementDocObjectsFailure = createAction(
  typeName('getMovementDocObjects: Failure'),
  failureProps
);
const getMovementDocObjectsIsFresh = createAction(typeName('getMovementDocObjects: Is Fresh'));
const getMovementDocObjectsCheckFreshness = createAction(
  typeName('getMovementDocObjects: Check Freshness')
);

export const movementDocObjectsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getMovementDocObjectsTrigger,
    success: getMovementDocObjectsSuccess,
    failure: getMovementDocObjectsFailure,
    isFresh: getMovementDocObjectsIsFresh,
    checkFreshness: getMovementDocObjectsCheckFreshness,
  },
};
