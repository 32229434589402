import { createAction, props } from '@ngrx/store';
import { INVENTORY_LIST_STATUSES } from '../inventory-list-statuses.constants';
import { InventoryListStatusesState } from './inventory-list-statuses.reducer';

const typeName = (name: string) =>
  `[${INVENTORY_LIST_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getInventoryListStatusesTrigger = createAction(
  typeName('getInventoryListStatuses: Trigger')
);
const getInventoryListStatusesSuccess = createAction(
  typeName('getInventoryListStatuses: Success'),
  props<{ data: InventoryListStatusesState['data'] }>()
);
const getInventoryListStatusesFailure = createAction(
  typeName('getInventoryListStatuses: Failure'),
  failureProps
);
const getInventoryListStatusesIsFresh = createAction(
  typeName('getInventoryListStatuses: Is Fresh')
);
const getInventoryListStatusesCheckFreshness = createAction(
  typeName('getInventoryListStatuses: Check Freshness')
);

export const inventoryListStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getInventoryListStatusesTrigger,
    success: getInventoryListStatusesSuccess,
    failure: getInventoryListStatusesFailure,
    isFresh: getInventoryListStatusesIsFresh,
    checkFreshness: getInventoryListStatusesCheckFreshness,
  },
};
