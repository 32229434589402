import { Join } from '@monorepo/shared';

/**
 * Function like `path.join`
 *
 * @param {Array<String>} args
 * @returns
 */
export const makeUrl = <T extends string[]>(...args: T) =>
  args.join('/') as Join<T, '/'>;
