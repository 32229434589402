import { createAction, props } from '@ngrx/store';
import { TRADE_ITEM_GROUPS } from '../trade-item-groups.constants';
import { TradeItemGroupsState } from './trade-item-groups.reducer';

const typeName = (name: string) =>
  `[${TRADE_ITEM_GROUPS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getTradeItemGroupsTrigger = createAction(
  typeName('getTradeItemGroups: Trigger')
);
const getTradeItemGroupsSuccess = createAction(
  typeName('getTradeItemGroups: Success'),
  props<{ data: TradeItemGroupsState['data'] }>()
);
const getTradeItemGroupsFailure = createAction(
  typeName('getTradeItemGroups: Failure'),
  failureProps
);
const getTradeItemGroupsIsFresh = createAction(
  typeName('getTradeItemGroups: Is Fresh')
);
const getTradeItemGroupsCheckFreshness = createAction(
  typeName('getTradeItemGroups: Check Freshness')
);

export const tradeItemGroupsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getTradeItemGroupsTrigger,
    success: getTradeItemGroupsSuccess,
    failure: getTradeItemGroupsFailure,
    isFresh: getTradeItemGroupsIsFresh,
    checkFreshness: getTradeItemGroupsCheckFreshness,
  },
};
