/* eslint-disable max-lines */
import { CommonModule } from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs';
import { AccountService } from '../account.service';
import { AuthService } from '../auth.service';
import { ChangePlaceComponent } from './change-place.component';
import {DialogBlocksModule} from "@12stz/ui-kit/molecules/dialog-blocks";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

type DialogInputData = {
  process: string | undefined;
  allowedPlaces: string[];
};
type DialogResult = boolean;

export type ChangePlaceDialogOpenFNType = [
  ChangePlaceDialogComponent,
  DialogInputData,
  DialogResult
];

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ChangePlaceComponent,
    DialogBlocksModule,
  ],
  template: `
    <sz-dialog-header>Измените место | {{process}}</sz-dialog-header>
    <sz-dialog-body>
      <monorepo-change-place
        [allowedPlaces]="allowedPlaces"
        [currentPlaceName]="workplaceName$ | async"
        [loading]="loading$ | async"
        (scanPlace)="onScanPlace($event)"
      ></monorepo-change-place>
    </sz-dialog-body>
  `,
})
export class ChangePlaceDialogComponent implements OnInit {
  public process = '';
  public allowedPlaces: string[] = [];
  private authService = inject(AuthService);
  private accountService = inject(AccountService);
  public dialogRef = inject(DialogRef<boolean>);
  private data: DialogInputData = inject(DIALOG_DATA);
  public loading$ = this.accountService.changePlace.loading$;
  public workplaceName$ = this.authService.workPlace$.pipe(
    map((workplace) => workplace?.name || null),
    distinctUntilChanged()
  );
  public placeAllowed$ = this.workplaceName$.pipe(
    map((place) => {
      return this.allowedPlaces.some((allowedPlace) =>
        place?.includes(allowedPlace)
      );
    })
  );


  ngOnInit() {
    this.process = this.data.process || this.process;
    this.allowedPlaces = this.data.allowedPlaces || this.allowedPlaces;
  }

  onScanPlace(place: string) {
    this.accountService
      .changePlace(place)
      .pipe(
        take(1),
        switchMap(() => this.placeAllowed$.pipe(take(1))),
        tap((allowed) => {
          allowed && this.close();
        })
      )
      .subscribe();
  }

  close() {
    this.placeAllowed$.pipe(take(1)).subscribe((allowed) => {
      this.dialogRef.close(allowed);
    });
  }
}
