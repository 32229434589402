import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INVENTORY_COUNTING_TYPES } from '../inventory-counting-types.constants';
import { InventoryCountingTypesState } from './inventory-counting-types.reducer';

const state = createFeatureSelector<InventoryCountingTypesState>(
  INVENTORY_COUNTING_TYPES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const inventoryCountingTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
