import { LocalStorageService } from '@monorepo/ui/services/browser';
import { ActionReducer, Action } from '@ngrx/store';
import { merge, pick } from 'lodash';

export function storageMetaReducer<S, A extends Action = Action>(
  localStorageKey: string,
  storageService: LocalStorageService,
  saveKeys?: string[]
) {
  let onInit = true; // after load/refresh…
  return function (reducer: ActionReducer<S, A>) {
    return function (state: S, action: A): S {
      // get to the nextState.
      const nextState = reducer(state, action);
      // init the application state.
      if (onInit) {
        onInit = false;
        const savedState = storageService.getItem(localStorageKey);
        return merge({ ...nextState }, savedState);
      }
      // save the next state to the application storage.
      const stateToSave = saveKeys ? pick(nextState, saveKeys) : nextState;
      storageService.setItem(localStorageKey, stateToSave);
      return nextState;
    };
  };
}

export function getMetaReducerConfig(
  localStorageKey: string,
  storageService: LocalStorageService,
  saveKeys: string[]
) {
  return {
    metaReducers: [
      storageMetaReducer(localStorageKey, storageService, saveKeys),
    ],
  };
}
