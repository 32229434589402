import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { SnackbarData } from '../snackbar.interfaces';

@Component({
  selector: 'monorepo-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarComponent {
  ICON_TYPES = {
    warn: 'error_outline',
    success: 'check_circle_outline',
    error: 'block',
    info: 'info_outline',
  };

  constructor(
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }

  getIconClass() {
    return {
      warn: this.data.type === 'warn',
      error: this.data.type === 'error',
      success: this.data.type === 'success',
      info: this.data.type === 'info',
    };
  }

  getIcon() {
    return this.data.type ? this.ICON_TYPES[this.data.type] : null;
  }
}
