import { createAction, props } from '@ngrx/store';
import { INVENTORY_STATUSES } from '../inventory-statuses.constants';
import { InventoryStatusesState } from './inventory-statuses.reducer';

const typeName = (name: string) =>
  `[${INVENTORY_STATUSES.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getInventoryStatusesTrigger = createAction(
  typeName('getInventoryStatuses: Trigger')
);
const getInventoryStatusesSuccess = createAction(
  typeName('getInventoryStatuses: Success'),
  props<{ data: InventoryStatusesState['data'] }>()
);
const getInventoryStatusesFailure = createAction(
  typeName('getInventoryStatuses: Failure'),
  failureProps
);
const getInventoryStatusesIsFresh = createAction(
  typeName('getInventoryStatuses: Is Fresh')
);
const getInventoryStatusesCheckFreshness = createAction(
  typeName('getInventoryStatuses: Check Freshness')
);

export const inventoryStatusesActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getInventoryStatusesTrigger,
    success: getInventoryStatusesSuccess,
    failure: getInventoryStatusesFailure,
    isFresh: getInventoryStatusesIsFresh,
    checkFreshness: getInventoryStatusesCheckFreshness,
  },
};
