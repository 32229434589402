import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { API_URL } from '@monorepo/ui/core';

import { DICTIONARIES_USERS_API } from '@monorepo/dictionaries/shared';
import { makeUrl } from '@monorepo/ui/helpers';
import { UserDto } from '@monorepo/dictionaries/dto';

const { CONTROLLER, BY_ROLE } = DICTIONARIES_USERS_API;

type GetByRole = Parameters<typeof DICTIONARIES_USERS_API['BY_ROLE']>[0];

@Injectable({ providedIn: 'root' })
export class UsersHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getAll() {
    const URL = this.BASE_URL();
    return this.http.get<UserDto[]>(URL);
  }

  getByRole(params: GetByRole) {
    const { url } = BY_ROLE(params);
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<UserDto[]>(URL);
  }
}
