import { Injectable } from '@angular/core';
import { DictionariesHttpService } from '../dictionaries-http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductDefectsService {
  constructor(private dictionariesHttpService: DictionariesHttpService) {}

  getData() {
    return this.dictionariesHttpService.getProductDefects();
  }
}
