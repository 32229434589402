import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs';
import { conditionsActions } from './conditions.actions';
import { conditionsSelectors } from './conditions.selectors';

export enum ConditionTypesEnum {
  ALL = 'conditions$',
  FOR_ACCEPTANCE = 'conditionsForAcceptance$',
  FOR_CHANGING_CONDITION = 'conditionsForChangingCondition$',
  FOR_INTEGRATION = 'conditionsForIntegration$',
  FOR_MOVEMENT = 'conditionsForMovement$',
  FOR_RESERVE = 'conditionsForReserve$',
}

// also facades can be used instead of effects
// https://gist.github.com/ThomasBurleson/38d067abad03b56f1c9caf28ff0f4ebd
@Injectable({
  providedIn: 'root',
})
export class ConditionsFacade {
  loading$ = this.store.select(conditionsSelectors.loading);
  updatedAt$ = this.store.select(conditionsSelectors.updatedAt);
  data$ = this.store.select(conditionsSelectors.conditions.all).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  conditions$ = this.data$;
  conditionsForAcceptance$ = this.data$.pipe(
    map((arr) => arr.filter((cond) => cond.forAcceptance))
  );
  conditionsForChangingCondition$ = this.data$.pipe(
    map((arr) => arr.filter((cond) => cond.forChangingCondition))
  );
  conditionsForIntegration$ = this.data$.pipe(
    map((arr) => arr.filter((cond) => cond.forIntegration))
  );
  conditionsForMovement$ = this.data$.pipe(
    map((arr) => arr.filter((cond) => cond.forMovement))
  );
  conditionsForReserve$ = this.data$.pipe(
    map((arr) => arr.filter((cond) => cond.forReserve).sort((prev, next) => prev.id - next.id))
  );

  types = ConditionTypesEnum;

  constructor(private store: Store) {}

  getConditionsByType(type: ConditionTypesEnum) {
    return this[type];
  }

  checkFreshness() {
    this.store.dispatch(conditionsActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(conditionsActions.getData.trigger());
  }
}
