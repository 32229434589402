import { AcceptanceStatusDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { acceptanceStatusesActions } from './acceptance-statuses.actions';

export type AcceptanceStatusesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: AcceptanceStatusDto[];
};

export const initialAcceptanceStatusesState: AcceptanceStatusesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.acceptanceStatuses,
  data: [],
};

export const acceptanceStatusesReducer = createReducer(
  initialAcceptanceStatusesState,

  // state
  on(acceptanceStatusesActions.state.clear, () => ({
    ...initialAcceptanceStatusesState,
  })),

  // getAcceptanceStatuses
  on(acceptanceStatusesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(acceptanceStatusesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(acceptanceStatusesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
