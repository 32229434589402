import { DepartmentDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { departmentsActions } from './departments.actions';

export type DepartmentsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: DepartmentDto[];
};

export const initialDepartmentsState: DepartmentsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.departments,
  data: [],
};

export const departmentsReducer = createReducer(
  initialDepartmentsState,

  // state
  on(departmentsActions.state.clear, () => ({ ...initialDepartmentsState })),

  // getData
  on(departmentsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(departmentsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(departmentsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
