import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TradeItemGroupsEffects } from './store/trade-item-groups.effects';
import { TRADE_ITEM_GROUPS } from './trade-item-groups.constants';
import { tradeItemGroupsReducer } from './store/trade-item-groups.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      TRADE_ITEM_GROUPS.FEATURE_NAME,
      tradeItemGroupsReducer
    ),
    EffectsModule.forFeature([TradeItemGroupsEffects]),
    RouterModule.forChild([]),
  ],
})
export class TradeItemGroupsModule {}
