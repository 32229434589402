/* eslint-disable max-lines */
import { CommonModule } from '@angular/common';
import { Component, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { Subject } from 'rxjs';

@Component({
  selector: 'monorepo-pwa-update-actions',
  standalone: true,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  template: `
    <button
      type="button"
      aria-label="update now"
      color="primary"
      mat-raised-button
      (click)="updateNow.next()"
    >
      {{ acceptBtnText }}
    </button>

    <button mat-raised-button [matMenuTriggerFor]="menu" aria-label="update in">
      {{ postponeBtnText }}
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let item of times"
        (click)="updateIn.next(item.value)"
      >
        {{ item.label }}
      </button>
    </mat-menu>
  `,
  imports: [CommonModule, MatMenuModule, MatButtonModule],
})
export class PwaUpdateActionsComponent {
  @Input() acceptBtnText = 'Обновить';
  @Input() postponeBtnText = 'Напомнить через...';
  @Input() times = [
    { label: '10 минут', value: 600_000 },
    { label: '30 минут', value: 1800_000 },
    { label: '1 час', value: 3600_000 },
  ];

  @Output() updateNow = new Subject<void>();
  @Output() updateIn = new Subject<number>();
}
