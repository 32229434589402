import { createAction, props } from '@ngrx/store';
import {SUPERVISORS} from "../supervisors-all.constants";
import {
  SupervisorsState
} from "./supervisors.reducer";

const typeName = (name: string) => `[${SUPERVISORS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getSupervisorsTrigger = createAction(typeName('getSupervisors: Trigger'));
const getSupervisorsSuccess = createAction(
  typeName('getSupervisors: Success'),
  props<{ data: SupervisorsState['data'] }>()
);
const getSupervisorsFailure = createAction(
  typeName('getSupervisors: Failure'),
  failureProps
);
const getSupervisorsIsFresh = createAction(typeName('getSupervisors: Is Fresh'));
const getSupervisorsCheckFreshness = createAction(
  typeName('getSupervisors: Check Freshness')
);

export const supervisorsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getSupervisorsTrigger,
    success: getSupervisorsSuccess,
    failure: getSupervisorsFailure,
    isFresh: getSupervisorsIsFresh,
    checkFreshness: getSupervisorsCheckFreshness,
  },
};
