import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SsccTypesEffects } from './store/sscc-types.effects';
import { SSCC_TYPES } from './sscc-types.constants';
import { ssccTypesReducer } from './store/sscc-types.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SSCC_TYPES.FEATURE_NAME, ssccTypesReducer),
    EffectsModule.forFeature([SsccTypesEffects]),
  ],
})
export class SsccTypesModule {}
