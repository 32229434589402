import { createAction, props } from '@ngrx/store';
import { USERS_MASTERS } from '../users-masters.constants';
import { UsersMastersState } from './users-masters.reducer';

const typeName = (name: string) => `[${USERS_MASTERS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getUsersMastersTrigger = createAction(
  typeName('getUsersMasters: Trigger')
);
const getUsersMastersSuccess = createAction(
  typeName('getUsersMasters: Success'),
  props<{ data: UsersMastersState['data'] }>()
);
const getUsersMastersFailure = createAction(
  typeName('getUsersMasters: Failure'),
  failureProps
);
const getUsersMastersIsFresh = createAction(
  typeName('getUsersMasters: Is Fresh')
);
const getUsersMastersCheckFreshness = createAction(
  typeName('getUsersMasters: Check Freshness')
);

export const usersMastersActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getUsersMastersTrigger,
    success: getUsersMastersSuccess,
    failure: getUsersMastersFailure,
    isFresh: getUsersMastersIsFresh,
    checkFreshness: getUsersMastersCheckFreshness,
  },
};
