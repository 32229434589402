import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONDITIONS } from '../conditions.constants';
import { ConditionsState } from './conditions.reducer';

const state = createFeatureSelector<ConditionsState>(CONDITIONS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const conditionsSelectors = {
  state,
  loading,
  updatedAt,
  conditions: { all: data },
};
