import { ProductZoneDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { productZonesActions } from './product-zones.actions';

export type ProductZonesState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: ProductZoneDto[];
};

export const initialProductZonesState: ProductZonesState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.productZones,
  data: [],
};

export const productZonesReducer = createReducer(
  initialProductZonesState,

  // state
  on(productZonesActions.state.clear, () => ({ ...initialProductZonesState })),

  // getProductZones
  on(productZonesActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(productZonesActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(productZonesActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
