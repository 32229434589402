import { Injectable } from '@angular/core';
import { InventoryHttpService } from '../inventory-http.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryStatusesService {
  constructor(private inventoryHttpService: InventoryHttpService) {}

  getData() {
    return this.inventoryHttpService.getStatuses();
  }
}
