export const AUTH_SAC = {
  CONTROLLER: () => {
    return {
      url: 'auth' as const,
    };
  },
  LOGIN: () => {
    return {
      url: '' as const,
    };
  },
};
