import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {
  movementObjectReducer
} from "./store/movement-object.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  MovementObjectEffects
} from "./store/movement-object.effects";
import {
  MOVEMENT_DOC_OBJECTS
} from "./object-all.constants";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(MOVEMENT_DOC_OBJECTS.FEATURE_NAME, movementObjectReducer),
    EffectsModule.forFeature([MovementObjectEffects])
  ]
})
export class ObjectAllModule {}
