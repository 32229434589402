import { createAction, props } from '@ngrx/store';
import { DEFECT_COMMENTS } from '../defect-comments.constants';
import { DefectCommentsState } from './defect-comments.reducer';

const typeName = (name: string) => `[${DEFECT_COMMENTS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getDefectCommentsTrigger = createAction(
  typeName('getDefectComments: Trigger')
);
const getDefectCommentsSuccess = createAction(
  typeName('getDefectComments: Success'),
  props<{ data: DefectCommentsState['data'] }>()
);
const getDefectCommentsFailure = createAction(
  typeName('getDefectComments: Failure'),
  failureProps
);
const getDefectCommentsIsFresh = createAction(
  typeName('getDefectComments: Is Fresh')
);
const getDefectCommentsCheckFreshness = createAction(
  typeName('getDefectComments: Check Freshness')
);

export const defectCommentsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getDefectCommentsTrigger,
    success: getDefectCommentsSuccess,
    failure: getDefectCommentsFailure,
    isFresh: getDefectCommentsIsFresh,
    checkFreshness: getDefectCommentsCheckFreshness,
  },
};
