import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCEPTANCE_TYPES } from '../acceptance-types.constants';
import { AcceptanceTypesState } from './acceptance-types.reducer';

const state = createFeatureSelector<AcceptanceTypesState>(
  ACCEPTANCE_TYPES.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const acceptanceTypesSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
