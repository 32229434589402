import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersMastersModule } from './users-masters';
import { UsersAllModule } from './users-all';

@NgModule({
  imports: [CommonModule, UsersMastersModule],
  exports: [UsersMastersModule, UsersAllModule],
})
export class UsersModule {}
