import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '@monorepo/ui/core';
import { makeUrl, restQuery } from '@monorepo/ui/helpers';

import {
  DICTIONARIES_TOPOLOGY_API,
  QUERY_PARAMS,
} from '@monorepo/dictionaries/shared';
import { SectorDto } from '@monorepo/dictionaries/dto';

const { CONTROLLER, SECTORS_BY_ZONE } = DICTIONARIES_TOPOLOGY_API;

@Injectable({
  providedIn: 'root',
})
export class TopologyHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getSectorsByZone = restQuery(
    ({ query }: { query: { zoneId: number | string } }) => {
      const { zoneId } = query;
      const { url } = SECTORS_BY_ZONE({
        [QUERY_PARAMS]: [zoneId.toString() as any],
      });
      const URL = makeUrl(this.BASE_URL, url);
      return this.http.get<SectorDto[]>(URL, { params: { zoneId } });
    }
  );
}
