import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SupervisorsState
} from "./supervisors.reducer";
import {SUPERVISORS} from "../supervisors-all.constants";

const state = createFeatureSelector<SupervisorsState>(SUPERVISORS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const supervisorsSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
