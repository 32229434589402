import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ZONES_FOR_LOCK } from '../zones-for-lock.constants';
import { ZonesForLockState } from './zones-for-lock.reducer';

const state = createFeatureSelector<ZonesForLockState>(
  ZONES_FOR_LOCK.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const zonesForLockSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
