import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {TRANSPORT_COMPANIES_FOR_INTEGRATION} from "./tc-for-integration.constants";
import {
  transportCompaniesForIntegrationReducer
} from "./store/transport-companies-for-integration.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  TransportCompaniesForIntegrationEffects
} from "./store/transport-companies-for-integration.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(TRANSPORT_COMPANIES_FOR_INTEGRATION.FEATURE_NAME, transportCompaniesForIntegrationReducer),
    EffectsModule.forFeature([TransportCompaniesForIntegrationEffects])
  ]
})
export class TcForIntegrationModule {}
