import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {TRANSPORT_COMPANIES} from "./tc-all.constants";
import {
  transportCompaniesReducer
} from "./store/transport-companies.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  TransportCompaniesEffects
} from "./store/transport-companies.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(TRANSPORT_COMPANIES.FEATURE_NAME, transportCompaniesReducer),
    EffectsModule.forFeature([TransportCompaniesEffects])
  ]
})
export class TcAllModule {}
