import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  MovementDocObjectsState
} from "./movement-object.reducer";
import {
  MOVEMENT_DOC_OBJECTS
} from "../object-all.constants";

const state = createFeatureSelector<MovementDocObjectsState>(MOVEMENT_DOC_OBJECTS.FEATURE_NAME);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const movementDocObjectsSelector = {
  data,
  state,
  loading,
  updatedAt,
};
