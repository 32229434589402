import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {SUPERVISORS} from "./supervisors-all.constants";
import {
  supervisorsReducer
} from "./store/supervisors.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  SupervisorsEffects
} from "./store/supervisors.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(SUPERVISORS.FEATURE_NAME, supervisorsReducer),
    EffectsModule.forFeature([SupervisorsEffects])
  ]
})
export class SupervisorsAllModule {}
