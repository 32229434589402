import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {REGISTRY_STATUSES} from "./registry-statuses-all.constants";
import {
  registryStatusesReducer
} from "./store/registry-statuses.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  RegistryStatusesEffects
} from "./store/registry-statuses.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(REGISTRY_STATUSES.FEATURE_NAME, registryStatusesReducer),
    EffectsModule.forFeature([RegistryStatusesEffects])
  ]
})
export class RegistryStatusesAllModule {}
