const TEST_PASSWORD = 'test';

function password(pass: string) {
  return (isProduction: boolean) => (isProduction ? pass : TEST_PASSWORD);
}

const SAME_PASSWORD = 'PMLZ0TRMK';

export const featurePasswords = {
  items: {
    uiidPrinting: password(SAME_PASSWORD),
    uiidGenerate: password(SAME_PASSWORD),
    editDefects: password(SAME_PASSWORD),
  },
  acceptance: {
    rollbackAcceptance: password(SAME_PASSWORD),
  },
  sscc: {
    print: password(SAME_PASSWORD),
    generate: password(SAME_PASSWORD),
  },
};
