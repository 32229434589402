import {Injectable} from "@angular/core";
import {tap} from "rxjs";
import {Store} from "@ngrx/store";
import {mistakesSelectors} from "./mistakes.selector";
import {mistakesActions} from "./mistakes.actions";

@Injectable({
  providedIn: 'root',
})
export class MistakesFacade {
  loading$ = this.store.select(mistakesSelectors.loading);
  updatedAt$ = this.store.select(mistakesSelectors.updatedAt);
  data$ = this.store.select(mistakesSelectors.data).pipe(
    tap({
      subscribe: () => this.checkFreshness(),
    })
  );

  constructor(private store: Store) {}

  checkFreshness() {
    this.store.dispatch(mistakesActions.getData.checkFreshness());
  }

  refresh() {
    this.store.dispatch(mistakesActions.getData.trigger());
  }
}
