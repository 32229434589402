import { createAction, props } from '@ngrx/store';
import {BlockedItemSummaryDto, ItemSummaryDto} from '@monorepo/item/dto';
import {UNLOCK_ITEMS} from "../unlock-items.constants";

const typeName = (name: string) => `[${UNLOCK_ITEMS.FEATURE_NAME}] ${name}`;
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const openTrigger = createAction(
  typeName('openUnlockItem: Trigger'),
  props<{ sscc: string, returnUrl: string }>()
)

const getLockedItemsTrigger = createAction(
  typeName('getLockedItems: Trigger'),
  props<{ sscc: string }>()
)

const getLockedItemsSuccess = createAction(
  typeName('getLockedItems: Success'),
  props<{ response: BlockedItemSummaryDto[] }>()
)

const getLockedItemsFailure = createAction(
  typeName('getLockedItems: Failure'),
  failureProps
)

const scanTargetBoxTrigger = createAction(
  typeName('scanTargetBox: Trigger'),
  props<{ targetSscc: string }>()
)

const scanTargetBoxSuccess = createAction(
  typeName('scanTargetBox: Success'),
  props<{ targetSscc: string }>()
)

const scanTargetBoxFailure = createAction(
  typeName('scanTargetBox: Failure'),
  failureProps
)

const putUnlockItemsTrigger = createAction(
  typeName('putUnlockItems: Trigger'),
  props<{ place: string }>()
)

const putUnlockItemsSuccess = createAction(
  typeName('putUnlockItems: Success')
)

const putUnlockItemsFailure = createAction(
  typeName('putUnlockItems: Failure'),
  failureProps,
)

const nextConditionTrigger = createAction(
  typeName('nextCondition: Trigger'),
  props<{ currentConditionId: number }>()
)

const nextConditionSuccess = createAction(
  typeName('nextCondition: Success'),
  props<{ scannedConditionIds: number[], nextItemSummary: BlockedItemSummaryDto }>()
)

const nextConditionFinish = createAction(
  typeName('nextCondition: Finish')
)

const nextItemTrigger = createAction(
  typeName('nextItem: Trigger'),
)

const nextItemSuccess = createAction(
  typeName('nextItem: Success'),
  props<{ scannedItemBarcodes: number[], nextItem: ItemSummaryDto }>()
)

const nextItemFinish = createAction(
  typeName('nextItem: Finish'),
)

const scanItemTrigger = createAction(
  typeName('scanItem: Trigger'),
    props<{ itemId: number }>()
)

const scanItemSuccess = createAction(
  typeName('scanItem: Success')
)

const scanItemFailure = createAction(
  typeName('scanItem: Failure'),
  failureProps,
)

const isAllowedTrigger = createAction(
  typeName('isAllowed: Trigger')
)

export const unlockItemActions = {
  state: {
    clear: stateClear,
  },
  open: {
    trigger: openTrigger,
  },
  getLockedItems: {
    trigger: getLockedItemsTrigger,
    success: getLockedItemsSuccess,
    failure: getLockedItemsFailure,
  },
  scanItem: {
    trigger: scanItemTrigger,
    success: scanItemSuccess,
    failure: scanItemFailure,
  },
  scanTargetBox: {
    trigger: scanTargetBoxTrigger,
    success: scanTargetBoxSuccess,
    failure: scanTargetBoxFailure,
  },
  putUnlockItems: {
    trigger: putUnlockItemsTrigger,
    success: putUnlockItemsSuccess,
    failure: putUnlockItemsFailure,
  },
  nextCondition: {
    trigger: nextConditionTrigger,
    success: nextConditionSuccess,
    finish: nextConditionFinish,
  },
  nextItem: {
    trigger: nextItemTrigger,
    success: nextItemSuccess,
    finish: nextItemFinish,
  },
  isAllowed: {
    trigger: isAllowedTrigger,
  }
};
