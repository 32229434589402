import { TradeItemGroupDto } from '@monorepo/dictionaries/dto';
import { createReducer, on } from '@ngrx/store';
import { DictionariesCacheTime } from '../../../dictionaries.cache-time';
import { tradeItemGroupsActions } from './trade-item-groups.actions';

export type TradeItemGroupsState = {
  loading: boolean;
  updatedAt: number;
  cacheTime: number;
  data: TradeItemGroupDto[];
};

export const initialTradeItemGroupsState: TradeItemGroupsState = {
  loading: false,
  updatedAt: 0,
  cacheTime: DictionariesCacheTime.tradeItemGroups,
  data: [],
};

export const tradeItemGroupsReducer = createReducer(
  initialTradeItemGroupsState,

  // state
  on(tradeItemGroupsActions.state.clear, () => ({
    ...initialTradeItemGroupsState,
  })),

  // getTradeItemGroups
  on(tradeItemGroupsActions.getData.success, (state, { data }) => {
    return { ...state, data, loading: false, updatedAt: Date.now() };
  }),

  // loadings
  on(tradeItemGroupsActions.getData.trigger, (state) => {
    return { ...state, loading: true };
  }),

  on(tradeItemGroupsActions.getData.failure, (state) => {
    return { ...state, loading: false };
  })
);
