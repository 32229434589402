import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const darkThemeClass = 'dark-theme';
const darkThemeLsKey = 'dark-theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isDark$ = new BehaviorSubject(
    localStorage.getItem(darkThemeLsKey) === 'true'
  );

  constructor(@Inject(DOCUMENT) private document: Document) {
    const isDark = this.isDark$.getValue();

    if (isDark) {
      this.document.body.classList.add(darkThemeClass);
    }
  }

  public toggle() {
    const isDark = this.isDark$.getValue();

    if (isDark) {
      this.document.body.classList.remove(darkThemeClass);
    } else {
      this.document.body.classList.add(darkThemeClass);
    }

    localStorage.setItem(darkThemeLsKey, `${!isDark}`);
    this.isDark$.next(!isDark);
  }
}
