import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StoreModule} from "@ngrx/store";
import {REGISTRY_TYPES} from "./registry-types-all.constants";
import {
  registryTypesReducer
} from "./store/registry-types.reducer";
import {EffectsModule} from "@ngrx/effects";
import {
  RegistryTypesEffects
} from "./store/registry-types.effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(REGISTRY_TYPES.FEATURE_NAME, registryTypesReducer),
    EffectsModule.forFeature([RegistryTypesEffects])
  ]
})
export class RegistryTypesAllModule {}
