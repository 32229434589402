import { createAction, props } from '@ngrx/store';
import { PRODUCT_DEFECTS } from '../product-defects.constants';
import { ProductDefectsState } from './product-defects.reducer';

const typeName = (name: string) => `[${PRODUCT_DEFECTS.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getProductDefectsTrigger = createAction(
  typeName('getProductDefects: Trigger')
);
const getProductDefectsSuccess = createAction(
  typeName('getProductDefects: Success'),
  props<{ data: ProductDefectsState['data'] }>()
);
const getProductDefectsFailure = createAction(
  typeName('getProductDefects: Failure'),
  failureProps
);
const getProductDefectsIsFresh = createAction(
  typeName('getProductDefects: Is Fresh')
);
const getProductDefectsCheckFreshness = createAction(
  typeName('getProductDefects: Check Freshness')
);

export const productDefectsActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getProductDefectsTrigger,
    success: getProductDefectsSuccess,
    failure: getProductDefectsFailure,
    isFresh: getProductDefectsIsFresh,
    checkFreshness: getProductDefectsCheckFreshness,
  },
};
