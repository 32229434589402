import { createFeatureSelector, createSelector } from '@ngrx/store';
import { USERS_MASTERS } from '../users-masters.constants';
import { UsersMastersState } from './users-masters.reducer';

const state = createFeatureSelector<UsersMastersState>(
  USERS_MASTERS.FEATURE_NAME
);

const loading = createSelector(state, ({ loading }) => loading);
const updatedAt = createSelector(state, ({ updatedAt }) => updatedAt);
const data = createSelector(state, ({ data }) => data);

export const usersMastersSelectors = {
  data,
  state,
  loading,
  updatedAt,
};
