import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { IS_PROD } from '@monorepo/ui/core';
import { bufferCount, Observable, Subject, takeUntil } from 'rxjs';
import { stringifyError } from '../../validators/errors';
import {InputControl} from "@12stz/ui-kit/controls/input";

@Component({
  selector: 'forms-base-scan-field',
  styleUrls: ['../shared-styles.scss'],
  templateUrl: './scan-field.component.html',
})
/**
 * 1. Передать label
 * 2. Передать placeholder
 * 3. Передать loading
 * 4?. Передать массив валидаторов
 */
export class ScanFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  fb = inject(FormBuilder);
  cdr = inject(ChangeDetectorRef);
  isProd = inject(IS_PROD);

  @Input() label = '';
  @Input() placeholder = '';
  @Input() autocomplete = this.isProd ? 'off' : undefined;
  @Input() loading?: boolean | null;
  @Input() disabled?: boolean | null;
  @Input() buttonText = 'Отправить';
  @Input() inputType: 'text' | 'number' = 'text';
  @Input() removeControls = false;
  @Input() required = true;
  @Input() showMultipleErrors = false;
  @Input() autofocus = true;
  @Input() reset$?: Observable<unknown>;
  @Input() textArea = false;

  /**
   * Validators might be easy changed from parent
   */
  @Input()
  set validators(v: ValidatorFn[] | null) {
    this.formControl.setValidators([
      ...(this.required ? [Validators.required] : []),
      ...(v || []),
    ]);
    this.formControl.updateValueAndValidity();
  }

  @Input()
  set asyncValidators(v: AsyncValidatorFn[] | null) {
    this.formControl.setAsyncValidators([...(v || [])]);
    this.formControl.updateValueAndValidity();
  }

  formControl = this.fb.nonNullable.control<string | undefined>('', [], []);
  form = this.fb.group<{ formField: FormControl<string | undefined> }>({
    formField: this.formControl,
  });

  @Output() formSubmit = new EventEmitter<string>();
  @Output() valueChanges = this.formControl.valueChanges;

  @ViewChild('scanFieldInput') input?: InputControl;

  stringifyError = stringifyError;

  reset() {
    this.form?.reset();
  }

  focus() {
    this.input?.focus();
  }

  ngOnInit() {
    if (!this.formControl.validator) this.validators = [];
    this.formControl.statusChanges
      .pipe(bufferCount(2), takeUntil(this.destroyed$))
      .subscribe((statuses) => {
        const [, current] = statuses;
        if (current === 'PENDING' || current === 'INVALID') {
          this.formControl.markAsTouched();
          this.formControl.markAsDirty();
        }
        this.cdr.markForCheck();
      });

    this.reset$?.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reset();
    });
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      setTimeout(() => {
        this.focus();
      });
    }
  }

  ngOnDestroy(): void {
    this.form.get('formField')?.setValue('');
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const { formField } = this.form.controls;
    this.formSubmit.emit(formField.value as string);
  }

  // for keyvalue pipe angular
  // https://stackoverflow.com/questions/52793944/angular-keyvalue-pipe-sort-properties-iterate-in-order
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  originalOrder = (a: unknown, b: unknown): number => {
    return 0;
  };
}
