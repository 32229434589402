import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OrderStatusesEffects } from './store/order-statuses.effects';
import { ORDER_STATUSES } from './order-statuses.constants';
import { orderStatusesReducer } from './store/order-statuses.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(ORDER_STATUSES.FEATURE_NAME, orderStatusesReducer),
    EffectsModule.forFeature([OrderStatusesEffects]),
  ],
})
export class OrderStatusesModule {}
