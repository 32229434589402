import { createAction, props } from '@ngrx/store';
import { ZONES_INVENTORY } from '../zones-inventory.constants';
import { ZonesInventoryState } from './zones-inventory.reducer';

const typeName = (name: string) => `[${ZONES_INVENTORY.FEATURE_NAME}] ${name}`;

// todo: типизировать ошибки с сервера
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const failureProps = props<{ error?: any }>();

const stateClear = createAction(typeName('STATE: clear'));

const getZonesInventoryTrigger = createAction(
  typeName('getZonesInventory: Trigger')
);
const getZonesInventorySuccess = createAction(
  typeName('getZonesInventory: Success'),
  props<{ data: ZonesInventoryState['data'] }>()
);
const getZonesInventoryFailure = createAction(
  typeName('getZonesInventory: Failure'),
  failureProps
);
const getZonesInventoryIsFresh = createAction(
  typeName('getZonesInventory: Is Fresh')
);
const getZonesInventoryCheckFreshness = createAction(
  typeName('getZonesInventory: Check Freshness')
);

export const zonesInventoryActions = {
  state: {
    clear: stateClear,
  },
  getData: {
    trigger: getZonesInventoryTrigger,
    success: getZonesInventorySuccess,
    failure: getZonesInventoryFailure,
    isFresh: getZonesInventoryIsFresh,
    checkFreshness: getZonesInventoryCheckFreshness,
  },
};
