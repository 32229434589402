import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { distinctUntilChanged, filter, shareReplay } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PwaService {
  constructor(
    private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: Document
  ) {}

  newVersion$ = this.swUpdate.versionUpdates.pipe(
    filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    distinctUntilChanged(),
    shareReplay()
  );

  updateNow() {
    this.document.location.reload();
  }
}
